import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isInputValueEmptyValidator } from 'src/app/core/shared/validators/inputValueEmptyValidator';

const CUSTOM_EMAIL_REGEX = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(?:\.(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){2,})(\s*)$/;

export function emailValidator(control: AbstractControl): ValidationErrors | null {
    if (control === null || isInputValueEmptyValidator(control.value)) {
        return null;
    }

    return CUSTOM_EMAIL_REGEX.test(control.value) ? null : { email: true };
}

export const emailStringValidator = (email: string): boolean | null => {
    return isInputValueEmptyValidator(email) ? null : CUSTOM_EMAIL_REGEX.test(email);
};

export const exportEmailPattern = (): RegExp => CUSTOM_EMAIL_REGEX;
