import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { GlobalConstant } from "../../models/base/global-constant";
import { OptionalFragmentCheckPlugin } from "../../plugins/optional-fragment-check/optional-fragment-check-plugin";
import { ALLOW_CONTENT_OF_VALUES, INHERIT_ALL_FROM_VALUES, DISALLOW_CHILDREN } from "../schema-values";

@Injectable({
    providedIn: 'root'
})
export class OptionalFragmentCheckSchemaService {

    constructor() {
    }

    public defineSchema(schema: Schema): void {
        this.registerContainer(schema);
        this.registerCheck(schema);
        this.registerDescription(schema);
        this.registerContent(schema);
    }

    private registerContainer(schema: Schema): void {
        schema.register(OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.CONTAINER,
            allowAttributes: [GlobalConstant.ATTRIBUTE_ID, OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.model ],
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            isObject: true
        });
    }

    private registerCheck(schema: Schema): void {
        schema.register(OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowAttributes: [
                GlobalConstant.ATTRIBUTE_ID,
                OptionalFragmentCheckPlugin.MODEL_ENTITIES.nameAttribute.model,
                OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.model],
            allowIn: [OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.model]
        });
    }

    private registerDescription(schema: Schema): void {
        schema.register(OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            allowIn: [OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.model],
            disallowChildren: [DISALLOW_CHILDREN.IMAGE_BLOCK, DISALLOW_CHILDREN.IMAGE_BLOCK, DISALLOW_CHILDREN.TABLE]
        });
    }

    private registerContent(schema: Schema): void {
        schema.register(OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
        });
    }
}
