import { Injectable } from '@angular/core';
import { Conversion, toWidget, toWidgetEditable } from 'ckeditor5';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { RadioEditionViewUtilsService } from '../../utils/radio/radio-edition-view-utils.service';

@Injectable({
    providedIn: 'root'
})
export class RadioModelToEditorViewConverterService {

    private editorUtilsService: RadioEditionViewUtilsService;

    constructor() {
        this.editorUtilsService = new RadioEditionViewUtilsService();
    }

    public configureConverters(conversion: Conversion) {
        this.containerConversion(conversion);
        this.optionCountAttributeConversion(conversion);
        this.optionConversion(conversion);
        this.inputConversion(conversion);
        this.descriptionConversion(conversion);
        this.contentConversion(conversion);
    }


    private optionCountAttributeConversion(conversion: Conversion) {
        conversion.for('editingDowncast').attributeToAttribute({
            model: RadioPlugin.MODEL_ENTITIES.optionCount.model,
            view: RadioPlugin.MODEL_ENTITIES.optionCount.editionView
        });
    }

    private contentConversion(conversion: Conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.content.model,
            view: (_modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createContentElementEditionView(
                    viewWriter
                );

                return toWidgetEditable(widgetElement, viewWriter);
            },
        });
    }

    private descriptionConversion(conversion: Conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.description.model,
            view: (_modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createDescriptionElementEditionView(viewWriter);
                return toWidgetEditable(widgetElement, viewWriter);
            },
        });
    }

    private inputConversion(conversion: Conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.input.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createRadiusElementEditionView(
                    modelItem,
                    viewWriter
                );

                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private optionConversion(conversion: Conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.option.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createOptionEditionView(
                    modelItem,
                    viewWriter
                );

                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private containerConversion(conversion: Conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.container.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createRadiusEditorView(
                    modelItem,
                    viewWriter
                );

                return toWidget(widgetElement, viewWriter);
            },
        });
    }
}
