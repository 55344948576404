import { Directive, Injectable, OnDestroy } from "@angular/core";
import { BasePlugin } from "../base/base-plugin";
import { Editor, PositionOptions, ContextualBalloon, Widget, ViewDocumentClickEvent, ClickObserver, DowncastWriter, ViewDocumentKeyDownEvent, KeyEventData, Writer, EventInfo } from "ckeditor5";
import { OptionalFragmentCheckSchemaService } from "../../schema/optional-fragment-check/optional-fragment-check-schema.service";
import { OptionalFragmentCheckDataViewToModelConverterService } from "../../converters/optional-fragment-check/data-view-to-model-converter.service";
import { FragmentCheckModelToEditorViewConverterService } from "../../converters/optional-fragment-check/model-to-editor-view-converter.service";
import { OptionalFragmentCheckModelToDataViewConverterService } from "../../converters/optional-fragment-check/model-to-data-view-converter.service";
import DeleteOptionalFragmentCheckCommand from "../../commands/optional-fragment-check/delete-optional-fragment-check-command";
import AddOptionalFragmentCheckCommand from "../../commands/optional-fragment-check/add-optional-fragment-check-command";
import { UserInterfaceService } from "../../ui/user-interface.service";
import OptionalFragmentCheckBalloonView from "../../ui/optional-fragment-check/optional-fragment-check-balloon-view.directive";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import { UI_CLASSES } from "../../ui/styles/styles-constants";
import { SchemaModel } from "../../models/schema-model";
import { KeyCode } from "../../models/base/keycode";
import { GlobalConstant } from "../../models/base/global-constant";

@Directive({
    selector: 'app-optional-fragment-check-plugin',
})
@Injectable({
    providedIn: 'root'
})
export class OptionalFragmentCheckPlugin extends BasePlugin implements OnDestroy {

    public static readonly PLUGIN_NAME = 'OptionalFragmentCheck';

    public static readonly ADD_COMMAND_NAME = 'add-optional-fragment';
    public static readonly DELETE_COMMAND_NAME = 'delete-optional-fragment';
    public static readonly DELETE_OPTION = $localize`:@@BorrarCheckFragment:BORRAR`;

    public static get pluginToolbarElementName() { return OptionalFragmentCheckPlugin.PLUGIN_NAME; }
    public static get visualSelectionMarker() { return OptionalFragmentCheckPlugin.PLUGIN_NAME; }
    public static get requires() { return [Widget, ContextualBalloon]; }

    public static readonly MODEL_ENTITIES: { [name: string]: SchemaModel } = {
        'container': { model: 'optional-fragment', dataView: 'opt', editionView: 'optional-fragment' },
        'check': { model: 'optional-fragment-check', dataView: '', editionView: 'optional-fragment-check' },
        'labelCheck': { model: '', dataView: 'checkbox-text', editionView: '' },
        'description': { model: 'optional-fragment-description', dataView: 'hDesc', editionView: 'optional-fragment-description' },
        'content': { model: 'optional-fragment-content', dataView: '', editionView: 'optional-fragment-content' },
        'checkAttribute': { model: 'checked', dataView: 'checked', editionView: 'checked' },
        'nameAttribute': { model: 'data-name', dataView: 'name', editionView: 'name' }
    }

    protected override mappers = [
        OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.editionView,
        OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.editionView,
        OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.editionView,
        OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.editionView
    ];

    protected override commands = {
        [OptionalFragmentCheckPlugin.ADD_COMMAND_NAME]: AddOptionalFragmentCheckCommand,
        [OptionalFragmentCheckPlugin.DELETE_COMMAND_NAME]: DeleteOptionalFragmentCheckCommand,
    };

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.CHECK_ICON,
        pluginToolbarElementName: OptionalFragmentCheckPlugin.pluginToolbarElementName,
        tooltip: $localize`:@@InsertarCheckOpcionalEtiquetaBotonBotoneraPlugin:Insertar un fragmento opcional`,
        hasTooltip: true,
        hasText: true
    };

    private balloonView: OptionalFragmentCheckBalloonView;
    private userInterfaceService: UserInterfaceService;
    private dataViewToModelConverter: OptionalFragmentCheckDataViewToModelConverterService;
    private modelToDataViewConverter: OptionalFragmentCheckModelToDataViewConverterService;
    private modelToEditorViewConverter: FragmentCheckModelToEditorViewConverterService;
    private optionalFragmentCheckSchema: OptionalFragmentCheckSchemaService;

    constructor(editor: Editor) {
        super(editor);
        this.optionalFragmentCheckSchema = new OptionalFragmentCheckSchemaService();
        this.dataViewToModelConverter = new OptionalFragmentCheckDataViewToModelConverterService();
        this.modelToDataViewConverter = new OptionalFragmentCheckModelToDataViewConverterService();
        this.modelToEditorViewConverter = new FragmentCheckModelToEditorViewConverterService();
        this.userInterfaceService = new UserInterfaceService();
    }

    protected defineSchema(): void {
        const schema = this.editor.model.schema;
        this.optionalFragmentCheckSchema.defineSchema(schema);
    }

    protected defineConverters(): void {
        const conversion = this.editor.conversion;
        this.dataViewToModelConverter.configureConverters(conversion);
        this.modelToDataViewConverter.configureConverters(conversion);
        this.modelToEditorViewConverter.configureConverters(conversion);
    }

    protected editorInteractions(): void {
        super.setupEditorObserver(ClickObserver);
        this.enableBalloonActivators();
    }

    protected override toolbarExecuteOperation(): void {
        this.editor.execute(OptionalFragmentCheckPlugin.ADD_COMMAND_NAME);
    }

    protected enableBalloonActivators(): void {
        const viewDocument = this.editor.editing.view.document;
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleClickEvent.bind(this));
        this.listenTo<ViewDocumentKeyDownEvent>(viewDocument, 'keydown', this.handleKeyDownEvent.bind(this), { priority: 'high' });
    }

    protected getBalloonPositionData(): Partial<PositionOptions> {
        return this.pluginUtils.getBalloonPositionData(this.editor, OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.editionView, OptionalFragmentCheckPlugin.visualSelectionMarker);
    }

    private handleClickEvent(): void {
        this.handleClickEventOnUI();
        this.handleClickEventOnCheck();
    }

    private handleClickEventOnUI(): void {
        const documentFragment = this.pluginUtils.getSelectedContainerWithClass(this.editor, OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.editionView);
        if (!documentFragment) {
            return;
        }
        const id = documentFragment.getAttribute(GlobalConstant.ATTRIBUTE_ID);

        if (!!id) {
            this.showUI(id);
        }

    }

    private showUI(id?: string): void {
        if (!this.balloonView) {
            this.createBalloonView(id);
        }

        this.userInterfaceService.addUI(this.editor, this.balloon, this.balloonView,
            OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.editionView, OptionalFragmentCheckPlugin.visualSelectionMarker);
    }

    private handleClickEventOnCheck(): void {
        const model = this.editor.model;
        const selectedElement = model.document.selection.getSelectedElement();

        if (!selectedElement || selectedElement.name !== OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.model) {
            return;
        }

        const selectedElementEditView = this.pluginUtils.getSelectedElementWithClass(this.editor, OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.editionView);

        const idCheck = selectedElementEditView.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        const elementInDom = document.querySelector(`input[id=${idCheck}]`);
        const isChecked = (elementInDom as HTMLInputElement)?.checked;

        model.change((writer: Writer) => {
            writer.setAttribute(OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.model, isChecked, selectedElement);
        });

        const editingView = this.editor.editing.view;
        editingView.change((writer: DowncastWriter) => {
            if (isChecked) {
                writer.setAttribute(OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.editionView, true, selectedElementEditView!);
            } else {
                writer.removeAttribute(OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.editionView, selectedElementEditView!);
            }
        });
    }

    private handleKeyDownEvent(event: EventInfo<'keydown'>, data: KeyEventData): void {
        const isAnyRemoveKeyPressed = data.keyCode === KeyCode.BACKSPACE || data.keyCode === KeyCode.DELETE;

        if (!isAnyRemoveKeyPressed) {
            return;
        }

        const selection = this.editor.model.document.selection;
        const firstPosition = selection.getFirstPosition();
        const blockElement = firstPosition!.parent;
        const parentBlockElement = blockElement.parent;

        const tryingDeleteBackspaceBegginingDescriptionOrContent = data.keyCode === KeyCode.BACKSPACE &&
            ((parentBlockElement?.name === OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.model ||
                parentBlockElement?.name === OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.model) &&
                firstPosition?.isAtStart);

        if (tryingDeleteBackspaceBegginingDescriptionOrContent) {
            data.preventDefault();
            data.stopPropagation();
            event.stop();
            event.return = true;
        }
    }

    private createBalloonView(id: string): void {
        this.balloonView = this.getBalloonView(id);
        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.balloonView,
            OptionalFragmentCheckPlugin.visualSelectionMarker, this);
    }

    private getBalloonView(id: string): OptionalFragmentCheckBalloonView {
        const editor = this.editor;
        const balloonView = new OptionalFragmentCheckBalloonView(editor.locale);
        balloonView.id = id;
        this.configureDeleteButton(editor, balloonView);

        return balloonView;
    }

    private configureDeleteButton(editor: Editor, balloonView: OptionalFragmentCheckBalloonView): void {
        balloonView.deleteButtonView.on('execute', () => {
            editor.execute(OptionalFragmentCheckPlugin.DELETE_COMMAND_NAME, balloonView.id);
            this.userInterfaceService.hideBalloonUI(this.editor, this.balloon, this.balloonView, OptionalFragmentCheckPlugin.visualSelectionMarker, this);
        });
    }
}
