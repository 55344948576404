import { Injectable } from "@angular/core";
import { DecoupledEditor, DowncastWriter, Editor, ViewContainerElement,
         ViewDocumentFragment, ViewElement, ViewNode } from "ckeditor5";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import { GlobalConstant } from "../../models/base/global-constant";
import { SignatureModel } from "../../models/signature/signature-model";
import { SignaturePlugin } from "../../plugins/signature/signature-plugin";

@Injectable({
    providedIn: "root",
})

export class SignatureUtilsService {

    private pluginUtils: PluginUtilsService;

    constructor() {
        this.pluginUtils = new PluginUtilsService();
    }

    public getSelectedSignatureElement(editor: Editor): ViewContainerElement | null {
        return this.pluginUtils.getSelectedContainerWithClass(editor, SignaturePlugin.MODEL_ENTITIES.class.editionView);
    }

    public getNumSignatures(editor: Editor): number {
        const data = (editor as DecoupledEditor).sourceElement;
        return data ? data.querySelectorAll(`.${SignaturePlugin.MODEL_ENTITIES.class.model}`).length : 0;

    }

    public hasRoleInDocument(editor: Editor, role: string, excludeSignatureId?: string): boolean {
        const data = (editor as DecoupledEditor).sourceElement;
        const queryFindRoleDifferentFromCurrent = excludeSignatureId
            ? `[${SignaturePlugin.ATTRIBUTE_ROLE_IS_STORED}="${role}"]:not([id="${excludeSignatureId}"])`
            : `[${SignaturePlugin.ATTRIBUTE_ROLE_IS_STORED}="${role}"]`;


        return data?.querySelector(queryFindRoleDifferentFromCurrent) !== null;
    }

    public getRole(element: ViewContainerElement | null): string {
        return element?.getAttribute(SignaturePlugin.ATTRIBUTE_ROLE_IS_STORED) || "";
    }

    public getSignatureId(element: ViewContainerElement | null): string {
        return element?.getAttribute("id") ?? "";
    }

    public createSignatureView(editor: Editor, modelItem: any, viewWriter: DowncastWriter): any {
        const role = modelItem.getAttribute(SignaturePlugin.ATTRIBUTE_ROLE_IS_STORED);
        const id = modelItem.getAttribute("id");
        return this.addSignatureTemplate(editor, viewWriter, role, id);
    }

    private addSignatureTemplate(editor: Editor, viewWriter: DowncastWriter, role: string, id: string): any {
        const showRole = true;
        const currentId = id ? id : "";
        const signature: SignatureModel = { id: currentId, role };

        const roleAndRoleToShow = this.getSignatureRoleAndRoleToShow(editor, signature, showRole);

        const element = viewWriter.createContainerElement(
            "div",
            {
                id: roleAndRoleToShow.id,
                "signature-title": roleAndRoleToShow.signatureRole,
            },
            [
                this.addSignatureTemplateContent( roleAndRoleToShow.signatureRole, roleAndRoleToShow.roleToShow, viewWriter),
            ]
        );
        viewWriter.addClass([SignaturePlugin.MODEL_ENTITIES.class.editionView, GlobalConstant.CONTAINER_CLASS_EDITION_VIEW],element);
        return element;
    }

    private addSignatureTemplateContent(signatureRole: string, rolToShow: string, viewWriter: DowncastWriter): any {
        const signatureRoleText = "[" + signatureRole + "]";
        const tdElement = viewWriter.createContainerElement(
            "td",
            { class: SignaturePlugin.SIGNATURE_SIGN_IN_PLACEMENT_CLASS },

            [viewWriter.createText(signatureRoleText)]
        );

        const tdFooterElement = viewWriter.createContainerElement(
            "td",
            { class: SignaturePlugin.SIGNATURE_SIGN_IN_ROL_CLASS },

            [viewWriter.createText(rolToShow)]
        );

        const contentElement = viewWriter.createContainerElement(
            "div",
            { class:SignaturePlugin.SIGNATURE_CONTENT_CLASS },
            [
                viewWriter.createContainerElement("table", null, [
                    viewWriter.createContainerElement("tbody", null, [
                        viewWriter.createContainerElement("tr", null, [
                            tdElement,
                        ]),
                    ]),
                    viewWriter.createContainerElement("trfoot", null, [
                        tdFooterElement,
                    ]),
                ]),
            ]
        );

        return contentElement;
    }

    private getSignatureRoleAndRoleToShow(editor: Editor, signature: SignatureModel, showRol: boolean): { signatureRole: string; roleToShow: string; id: string } {
        const id = signature.id?.trim() || `${SignaturePlugin.SIGNATURE_ID_BASE}${this.getNumSignatures(editor) + 1}`;
        const signatureRole = signature.role?.trim() || `${SignaturePlugin.SIGNATURE_ROLE_BASE}${this.getNumSignatures(editor) + 1}`;
        const roleToShow = showRol ? signatureRole : "";

        return { signatureRole, roleToShow, id };
    }

}
