import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export abstract class ITransformCleanHtmlService {
    abstract initializeHtmlEditor(): string;
    abstract addFormatRegulationsTab(html: string): string;
    abstract addIconsFormatRegulationsTab(html: string): string;
    abstract allElementsAreFilled(domNode: Document): boolean;
    abstract areElementInvalid(domNode: Document): boolean;
    abstract areTrackChangesPending(domNode: Document): boolean;
    abstract changeInputCurrentValueToValue(document: Document): void;
    abstract moveValuesTextService(document: Document, replaceFilledInput: boolean): string;
    abstract uncheckedInputRadioService(document: Document, replaceFilledInput: boolean): string;
    abstract transformHtmlFillInputsToTextService(document: Document, replaceFilledInput: boolean, cleanHeader: boolean): string;
    abstract cleanHeader(htmlText: string): string;
    abstract helpLabelAsPlaceholder(templateToClean: string): string;
    abstract cleanLinkFootNotes(htmlElement: Element): Element;
    abstract extractCO(htmlText: string): string;
    abstract cleanCheckBox(domNode: HTMLElement, onlyChecked: boolean): void;
    abstract cleanRadioButtons(domNode: HTMLElement, deleteEventIfNotSelected: boolean): void;
    abstract cleanRadioButtonsByEditor(domNode: HTMLElement): void;
    abstract cleanAllTextInputs(document: Document, domNode: HTMLElement): void;
    abstract cleanAllMailInputs(document: Document, domNode: HTMLElement): void;
    abstract cleanAllNumberInputs(document: Document, domNode: HTMLElement): void;
    abstract cleanAllDateInputs(document: Document, domNode: HTMLElement): void;
    abstract cleanAllInputs(document: Document, domNode: HTMLElement): void;
    abstract checkCleanAndIsSameText(originalText: string, checkedText: string): boolean;
    abstract cleanAllSelectionInputs(document: Document, domNode: HTMLElement): void;
    abstract cleanPlusMinus(domNode: HTMLElement): void;
    abstract cleanHelpNotes(domNode: HTMLElement): void;
    abstract noMandatoryElementsSelected(htmlText: string): boolean;
    abstract addMandatorySelectInputs(document: Document, selectToCheck: HTMLSelectElement[], titleValue: string);
    abstract addMandatoryRadioInputs(document: Document, radioToCheck: Element[], titleValue: string);
    abstract rBoxHideContent(htmlElement: Element);
    abstract transformLinks(htmlElement: Element, functionToClick: (anchorElement: Element) => void): void;
}
