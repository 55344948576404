import { Directive, OnDestroy } from "@angular/core";
import { ClickObserver, ContextualBalloon, Dialog, Editor,  ViewDocument, ViewDocumentClickEvent, Widget, DowncastWriter, Locale, DropdownView, Writer, DomEventData, EventInfo, ViewContainerElement, ViewSelection } from "ckeditor5";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import AddInputCommand from "../../commands/input/add-input-command";
import DeleteInputCommand from "../../commands/input/delete-input-command";
import EditInputCommand from "../../commands/input/edit-input-command";
import { InputDataViewToModelConverterService } from "../../converters/input/input-data-view-to-model-converter.service";
import { InputModelToDataViewConverterService } from "../../converters/input/input-model-to-data-view-converter.service";
import { InputModelToEditorViewConverterService } from "../../converters/input/input-model-to-editor-view-converter.service";
import { InputModel } from "../../models/input/input-model";
import InputBalloonView from "../../ui/input/input-balloon-view.directive";
import InputDialogView, { InputDialogFormViewCallback } from "../../ui/input/input-dialog-view.directive";
import { InputEditionViewUtilsService } from "../../utils/input/input-edition-view-utils.service";
import { InputDataViewUtilsService } from "../../utils/input/input-data-view-utils.service";
import { InputUtilsService } from "../../utils/input/input-utils.service";
import DoubleClickObserver, { ViewDocumentDoubleClickEvent } from "../../../utils/double-click-observer";
import { UI_CLASSES } from "../../ui/styles/styles-constants";
import { BasePlugin } from "../base/base-plugin";
import { InputSchemaService } from "../../schema/input/input-schema.service";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { DropdownViewModel, RowDropdownViewModel } from "../../ui/view/row-dropdown-view.model";
import CtboxDropdownView from "../../ui/view/dropdown-view.directive";
import { SchemaModel } from "../../models/schema-model";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import { GlobalConstant } from "../../models/base/global-constant";

@Directive({
    selector: "input-plugin",
})
export class InputPlugin extends BasePlugin implements OnDestroy {

    public static readonly PLUGIN_NAME = "Input-pg";
    public static readonly PLUGIN_MODEL = "input-pg";
    public static readonly PLUGIN_TOOLBAR_BUTTON_NAME = "text-Input-pg";

    public static readonly MODEL_ENTITIES:  {[name:string]: SchemaModel} = {
        'container'     :   { model: 'input-pg',                dataView:'input-plugin',            editionView: 'input-plugin'      },
        'content'       :   { model: 'input-content',           dataView:'input-content',           editionView: 'input-content'     },
        'help'          :   { model: 'input-help',              dataView:'data-input-help',         editionView: 'data-input-help'   },
        'type'          :   { model: 'input-type',              dataView:'type',                    editionView: 'type'              },
        'pattern'       :   { model: 'pattern',                 dataView:'data-pattern',            editionView: 'data-pattern'      },
        'transform'     :   { model: 'transform',               dataView:'data-transform',          editionView: 'data-transform'    },
        'isValid'       :   { model: 'is-valid',                dataView:'data-is-valid',           editionView: 'data-is-valid'     },
        'alias'         :   { model: 'alias',                   dataView:'data-alias',              editionView: 'data-alias'        },
    }

    public static readonly ATTRIBUTE_INPUT_IS_VALID_HIDDEN_EDITION_VIEW = "hidden";
    public static readonly ATTRIBUTE_INPUT_IS_VALID_CLASS_EDITION_VIEW = "input-is-valid";
    public static readonly ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW = "input-is-not-valid";

    public static readonly INPUT_CLASS_VALIDATE = "input-plugin-validate";
    public static readonly ID_INPUT_PREFFIX = 'ck-labeled-field-view-';
    public static readonly NAME_INPUT_PREFFIX = 'input-name-';
    public static readonly VISUAL_SELECTION_MARKER_NAME = 'input-pg';

    public static readonly ALLOWED_TYPES = ['date','email','number', 'text'];
    public static readonly TEXT_TYPE = 'text';
    public static readonly ATTRIBUTE_ALIAS_IS_STORED = "data-alias";

    public static readonly DELETE_COMMAND_NAME = "delete-input-pg";
    public static readonly EDIT_COMMAND_NAME = "edit-input-pg";
    public static readonly ADD_COMMAND_NAME = "add-input-pg";

    protected mappers = [
        InputPlugin.MODEL_ENTITIES.container.editionView
    ];

    protected commands = {
        [InputPlugin.ADD_COMMAND_NAME]: AddInputCommand,
        [InputPlugin.DELETE_COMMAND_NAME]: DeleteInputCommand,
        [InputPlugin.EDIT_COMMAND_NAME]: EditInputCommand
    };

    protected inputsFillMessage = $localize`:@@PluginInputBotoneraBotonPrincipalTooltip:Campos rellenables`;

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.ICON_INPUT,
        pluginToolbarElementName: InputPlugin.pluginToolbarElementName,
        buttonText: '',
        tooltip: this.inputsFillMessage,
        hasTooltip: true,
        hasText: true
    };

    private utilsService: InputUtilsService;
    private editionViewUtils: InputEditionViewUtilsService;

    private modelToEditorViewConverter: InputModelToEditorViewConverterService;
    private modelToDataViewConverter: InputModelToDataViewConverterService;
    private dataViewToModelConverter: InputDataViewToModelConverterService;
    private dataViewUtils : InputDataViewUtilsService;
    private inputSchemaService: InputSchemaService;
    private userInterfaceService: UserInterfaceService;

    private shortTextMessage = $localize`:@@PluginInputBotoneraTextoCorto:Texto Corto`;
    private largeTextMessage = $localize`:@@PluginInputBotoneraTextoLargo:Texto largo`;
    private fieldShortTextMessage = $localize`:@@PluginInputTituloAñadir:Campo Texto corto`;
    private fieldShortTextEditionMessage =$localize`:@@PluginInputTituloEdicion:Campo texto corto`;

    private inputWarningRepeated = $localize`:@@PluginInputAliasRepetido:Este alias ya está en el documento. Introduce otro.`
    private inputWarningLargeAlias =  $localize`:@@PluginInputAliasExcesoCaracteres:El alias no puede tener más de 30 caracteres.`
    private inputWarningAllowCharacters = $localize`:@@PluginInputAliasValidacionMinusculasYNumeros:Solo se permiten minúsculas sin acentos y números.`
    private inputWarningLargePlaceholder =  $localize`:@@PluginInputHelpExcesoCaracteres:El texto de ayuda no puede tener más de 100 caracteres.`

    private inputDialogView: InputDialogView;

    private inputBalloonView: InputBalloonView;

    private MAX_ALIAS_LENGTH = 30;
    private MAX_HELP_LENGTH = 100;

    constructor(editor: Editor) {
        super(editor);
        this.utilsService = new InputUtilsService();
        this.editionViewUtils = new InputEditionViewUtilsService();
        this.dataViewUtils = new InputDataViewUtilsService();
        this.pluginUtils = new PluginUtilsService();

        this.modelToEditorViewConverter = new InputModelToEditorViewConverterService();
        this.modelToDataViewConverter = new InputModelToDataViewConverterService();
        this.dataViewToModelConverter = new InputDataViewToModelConverterService();

        this.inputSchemaService = new InputSchemaService();
        this.userInterfaceService = new UserInterfaceService();
    }

    public static get requires() { return [Widget, ContextualBalloon, Dialog]; }
    public static get pluginName(): string { return InputPlugin.PLUGIN_NAME; }
    public static get pluginModelName(): string { return InputPlugin.PLUGIN_MODEL; }
    public static get pluginToolbarElementName(): string { return InputPlugin.PLUGIN_MODEL; }
    public static get mainToolbarButtonName() { return InputPlugin.PLUGIN_MODEL; }
    public static get inputTextToolbarButtonName() { return InputPlugin.PLUGIN_TOOLBAR_BUTTON_NAME; }

    public init(): void {
        super.init();
        this.defineCustomListeners();
    }

    protected defineSchema(): void {
       this.inputSchemaService.defineSchema(this.schema);
    }

    protected defineConverters(): void {
        this.dataViewToModelConverter.configureConverter(this.editor);
        this.modelToDataViewConverter.configureConverter(this.editor);
        this.modelToEditorViewConverter.configureConverter(this.editor);
    }

    protected editorInteractions(): void {
        this.defineObservers();
        this.balloon = this.editor.plugins.get(ContextualBalloon);
        this.enableBalloonActivators();
    }

    protected override toolbarInteractions(): void {
        const inputDropDownToolbar = this.createDropdownForToolbar();
        this.addDropdownToToolbar(inputDropDownToolbar.dropdown);
    }

    private createDropdownForToolbar(): CtboxDropdownView {
        const locale = new Locale();

        const modelTextShort: RowDropdownViewModel = {
            name: "textShortInput",
            titleText: this.shortTextMessage,
            helpText: $localize`:@@EjemploTextoCorto:Ej: nombre, apellido, ciudad`,
            iconSvg: UI_CLASSES.SVG_ICONS.ICON_SHORT_TEXT,
        };

        const modelTextLarge: RowDropdownViewModel = {
            name: "textLargeInput",
            titleText: this.largeTextMessage,
            helpText: $localize`:@@EjemploTextoLargo:Ej: comentarios, notas más extensas`,
            iconSvg: UI_CLASSES.SVG_ICONS.ICON_LONG_TEXT
        };

        const modelDropdown: DropdownViewModel = {
            titleText: "dropdown",
            helpText: $localize`:@@TooltipInputPlugin:Insertar campos rellenables`,
            iconSvg: UI_CLASSES.SVG_ICONS.ICON_INPUT,
            buttonRowDropdownViewModel: [modelTextShort, modelTextLarge]
        }

        const dropdownView = new CtboxDropdownView(locale, modelDropdown);
        this.handlerDropdownView(dropdownView);

        return dropdownView;
    }

    private addDropdownToToolbar(inputDropDownToolbar: DropdownView): DropdownView | null {
        this.editor.ui.componentFactory.add(
            InputPlugin.mainToolbarButtonName,
            () => {
                return inputDropDownToolbar;
            });
        return null;
    }

    private defineObservers(): void {
        this.editor.editing.view.addObserver(ClickObserver);
        this.editor.editing.view.addObserver(DoubleClickObserver);
    }

    private handlerDropdownView(dropdownView: CtboxDropdownView): void {
        dropdownView.on('dropdownClicked', (_, name: EventInfo) => {
            switch ((name.source as { model: { name: string } }).model.name) {
                case 'textShortInput':
                    this.handlerShortTextInputButton();
                    break;
                case 'textLargeInput':
                    this.handlerLargeTextInputButton();
                    break;
            }
        });
    }

    private handlerShortTextInputButton(): void {
        const dialog = this.editor.plugins.get("Dialog");

        this.inputDialogView = this.createEditInputDialogView();

        this.inputDialogView.resetFormStatus();
        dialog.show({
            isModal: true,
            content: this.inputDialogView,
            title: this.fieldShortTextMessage,
            id: "",
        });

    }

    private handlerLargeTextInputButton(): void {
       //TODO con la implementacion de texto large
       alert("Funcionalidad aún no implementada.");
    }

    private setValidAttribute(writer: Writer, contentElement: any, inputElementInModel: any): void {
        const classes = Array.from(contentElement.getClassNames());
        const elementsWithInvalidClass = classes.findIndex(classInArray => classInArray === InputPlugin.ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW);
        const isValid = elementsWithInvalidClass < 1;
        writer.setAttribute(InputPlugin.MODEL_ENTITIES.isValid.model, isValid, inputElementInModel.item);
    }

    private performTrasformation(writer: Writer, contentElement: any, inputElementInModel: any): void {
        const transform = contentElement?.getAttribute(InputPlugin.MODEL_ENTITIES.transform.editionView)!;
        if (!transform || transform === $localize`:@@EtiquetaNoDefinido:<No definido>`) {
            return;
        }
        this.utilsService.transformInputValue(writer, inputElementInModel, transform);
    }

    private defineCustomListeners(): void {
        const viewDocument = this.editor.editing.view.document;

        this.listenTo(viewDocument, 'blur', () => {
            const contentElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

            const id = contentElement?.getAttribute(GlobalConstant.ATTRIBUTE_ID);
            const inputElementInModel = this.pluginUtils.getSelectedInputElementModelWithId(this.editor, id);

            if (contentElement && inputElementInModel) {
                this.editor.model.change((writer: Writer) => {
                    this.setValidAttribute(writer, contentElement, inputElementInModel);
                    this.performTrasformation(writer, contentElement, inputElementInModel);
                });
            }
        });

        this.listenTo(viewDocument, 'change', () => {
            const contentElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

            if (!contentElement) {
                return;
            }

            const editingView = this.editor.editing.view;
            editingView.change((writer: DowncastWriter) => {
                this.changeEditingView(writer, contentElement);
            });
        });
    }

    private changeEditingView(writer: DowncastWriter, contentElement: ViewContainerElement): void {
        const value = this.editionViewUtils.getInputValue(contentElement);
        const pattern = contentElement?.getAttribute(InputPlugin.MODEL_ENTITIES.pattern.editionView)!;
        const isValid = this.utilsService.isDataValid(value, pattern);
        const classes = Array.from(contentElement.getClassNames());
        if (isValid) {
            const hasClassNotValid = classes.findIndex(classInArray => classInArray === InputPlugin.ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW) > 0;
            if (hasClassNotValid) {
                writer.removeClass(InputPlugin.ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW, contentElement);
                writer.addClass(InputPlugin.ATTRIBUTE_INPUT_IS_VALID_CLASS_EDITION_VIEW, contentElement);
            }
        } else {
            const hasClassValid = classes.findIndex(classInArray => classInArray === InputPlugin.ATTRIBUTE_INPUT_IS_VALID_CLASS_EDITION_VIEW) > 0;
            if (hasClassValid) {
                writer.removeClass(InputPlugin.ATTRIBUTE_INPUT_IS_VALID_CLASS_EDITION_VIEW, contentElement);
                writer.addClass(InputPlugin.ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW, contentElement);
            }
        }
    }

    private closeDialog(dialog: Dialog): void {
        dialog.hide();
    }

    private enableBalloonActivators(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;
        this.showBalloonOnClick(viewDocument);
    }

    private showBalloonOnClick(viewDocument: ViewDocument): void {
        this.clickInInputOpenBalloonHandler(viewDocument);
        this.doubleClickInInputToEditionHandler(viewDocument);
        this.selectionChangeClickHandler(viewDocument);
    }

    private clickInInputOpenBalloonHandler(viewDocument: ViewDocument): void {
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', (evt, data) => {
            const parentElement = this.pluginUtils.getSelectedContainerWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

            if (!parentElement) {
                return;
            }

            const inputModel = this.dataViewToModelConverter.getModel(parentElement);

            this.showInputBalloon(inputModel);
            data.preventDefault();
            data.stopPropagation();
            evt.stop();
            evt.return = true;
        });
    }

    private doubleClickInInputToEditionHandler(viewDocument: ViewDocument) {
        this.listenTo<ViewDocumentDoubleClickEvent>(viewDocument, 'dblclick', (evt: EventInfo, data: DomEventData) => {
            const inputModel: InputModel = this.getInputModel(data);

            if (!inputModel) {
                return;
            }

            this.showEditInputDialog(inputModel);
            data.preventDefault();
            data.stopPropagation();
            evt.stop();
            evt.return = true;
        }, { priority: "high" });
    }

    private getInputModel(data: DomEventData) : InputModel {
        const elementClicked = data.domTarget;
        const isInputContainer = (' ' + elementClicked.getAttribute('class') + ' ').includes(` ${InputPlugin.MODEL_ENTITIES.container.editionView} `);
        const isInputContent =  (' ' + elementClicked.getAttribute('class') + ' ').includes(` ${InputPlugin.MODEL_ENTITIES.content.editionView} `);

        if(isInputContainer) {
            return this.getModel(elementClicked);
        } else if( isInputContent) {
            return this.getModel(elementClicked.parentElement);
        } else {
            const parentElement = this.pluginUtils.getSelectedContainerWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

            return !!parentElement ? this.dataViewToModelConverter.getModel(parentElement) : null ;
        }
    }

    private getModel(viewElement: HTMLElement): InputModel {
        const id = viewElement.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        const alias = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.alias.editionView) ?? '';
        const helpNote = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.help.editionView) ?? '';
        const pattern = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.pattern.editionView) ?? '';
        const transform = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.transform.editionView) ?? '';
        const value = viewElement.getAttribute(GlobalConstant.ATTRIBUTE_VALUE) ?? '';
        const isValid = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.isValid.editionView) ?? '';
        const type = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.type.editionView) ?? '';

        const inputModel: InputModel = {
            id,
            alias: alias,
            help: helpNote,
            value,
            pattern,
            transform,
            isValid: isValid === 'true',
            type: type,
        };
        return inputModel;
    }

    private selectionChangeClickHandler(viewDocument: ViewDocument): void {
        this.listenTo(viewDocument, 'selectionChange', (evt, data) => {
            const oldSelection = data.oldSelection;
            const newSelection = data.newSelection;
            const fromInside = oldSelection.editableElement?.hasClass(InputPlugin.MODEL_ENTITIES.container.editionView) ||
                oldSelection.editableElement?.hasClass(InputPlugin.MODEL_ENTITIES.content.editionView);
            const toOutside = !newSelection.editableElement?.hasClass(InputPlugin.MODEL_ENTITIES.container.editionView) &&
                !newSelection.editableElement?.hasClass(InputPlugin.MODEL_ENTITIES.content.editionView);

            if(fromInside && toOutside) {
                this.resetSelectionAfterClick(newSelection);
            }

        }, { priority: "high" });
    }

    private resetSelectionAfterClick(newSelection: ViewSelection): void {
        const storedRanges = [];
        const mapper = this.editor.editing.mapper;
        for (const viewRange of newSelection.getRanges()) {
            storedRanges.push(mapper.toModelRange(viewRange));
        }

        const model = this.editor.editing.model;

        setTimeout(() => {
            const modelSelection = model.createSelection(storedRanges, {
                backward: newSelection.isBackward
            });
            model.change((writer) => {
                writer.setSelection(modelSelection);
            });
        }, 250);
    }

    private showInputBalloon(inputModel?: InputModel, forceVisible: boolean = false): void {
        if (!this.inputBalloonView) {
            this.addInputBalloonView(inputModel);
        }

        const inputElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

        if (!inputElement) {
            this.pluginUtils.showFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
        }

        this.addInputBalloonView(inputModel);

        if (forceVisible) {
            this.balloon.showStack("main");
        }

        this.startUpdatingUI();
    }

    private showEditInputDialog(inputModel?: InputModel, forceVisible: boolean = false): void {
        this.removeInputBalloonView();

        if (!this.inputDialogView) {
            this.addEditInputDialogView(inputModel);
        }

        const inputElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);
        if (!inputElement) {
            this.pluginUtils.showFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
        }

        this.addEditInputDialogView(inputModel);

        if (forceVisible) {
            this.balloon.showStack('main');
        }

        this.startUpdatingUI();
    }

    private addInputBalloonView(inputModel?: InputModel): void {
        if (!this.inputBalloonView) {
            this.createInputBalloonView();
        }

        if (this.userInterfaceService.isBalloonInPanel(this.balloon, this.inputBalloonView)) {
            const isSameInputEditing = this.inputBalloonView.id === inputModel?.id;
            if (!isSameInputEditing) {
                this.inputBalloonView.inputModel = inputModel!;
            }
            return;
        }

        this.inputBalloonView!.resetFormStatus();
        this.inputBalloonView.inputModel = inputModel!;

        this.balloon.add({
            view: this.inputBalloonView!,
            position:  this.pluginUtils.getBalloonPositionData(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView, InputPlugin.VISUAL_SELECTION_MARKER_NAME)
        });
    }

    private createInputBalloonView(): void {
        const editor = this.editor;
        this.inputBalloonView = new InputBalloonView(
            editor.locale
        );

        this.inputBalloonView.editButtonView.on('execute', () => {
            this.showEditInputDialog(this.inputBalloonView.inputModelToEdit);
        });

        this.inputBalloonView.deleteButtonView.on('execute', () => {
            editor.execute(InputPlugin.DELETE_COMMAND_NAME, this.inputBalloonView.id);
            this.hideUI();
        });

        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.inputBalloonView, InputPlugin.VISUAL_SELECTION_MARKER_NAME, this);
    }

    private addEditInputDialogView(inputModel?: InputModel): void {
        if (!this.inputDialogView) {
            this.inputDialogView = this.createEditInputDialogView(inputModel);
        }

        this.inputDialogView!.resetFormStatus();
        this.inputDialogView.inputModel = inputModel!;

        if (inputModel!.alias !== '') {
            this.inputDialogView.aliasButtonView.label = inputModel.alias;
            this.inputDialogView.deleteButtonView.isVisible = true;
            this.inputDialogView.infoTextView.element.classList.add('ck-icon-info-hidden');
        }

        const dialog = this.editor.plugins.get("Dialog");
        dialog.show({
            isModal: true,
            content: this.inputDialogView,
            title: this.fieldShortTextEditionMessage,
            onHide() {},
            id: "",
        });
        this.inputDialogView!.selectDefaultField();
    }

    private createEditInputDialogView(inputModel?: InputModel): InputDialogView {
        const formView = this.getEditInputDialogView(inputModel);
        return formView;
    }

    private getEditInputDialogView(inputModel?: InputModel): InputDialogView {
        const editor = this.editor;
        const validatorsAliasField = this.getAddInputFormValidatorsAliasField();
        const validatorsHelpField = this.getAddInputFormValidatorsHelpField();

        if (!this.inputDialogView) {
            this.inputDialogView = new InputDialogView(validatorsAliasField, validatorsHelpField, editor.locale, inputModel);
            this.handlerSubmitCancelInForm();
        } else {
            this.inputDialogView.resetFormStatus();
        }

        return this.inputDialogView;
    }

    private handlerSubmitCancelInForm(): void {
        const dialog = this.editor.plugins.get("Dialog");
        this.listenTo(this.inputDialogView, "submit", () => {
            const input = this.inputDialogView.getInputModel();

            if (!this.inputDialogView.isValidAllValidatorsField()) {
                return;
            }

            if (this.inputDialogView.isInCreation()) {
                this.editor.execute(InputPlugin.ADD_COMMAND_NAME, input);
            } else {
                this.editor.execute(InputPlugin.EDIT_COMMAND_NAME, input);
            }
            this.closeDialog(dialog);
            this.inputDialogView.resetFormStatus();
        });

        this.listenTo(this.inputDialogView, "cancel", () => {
            this.hideUI();
            this.closeDialog(dialog);
            this.inputDialogView.resetFormStatus();
        });
    }

    private hideUI(): void {
        if (!this.isUIInPanel()) {
            return;
        }

        const editor = this.editor;
        this.userInterfaceService.removeBalloonObservers(this.editor, this.balloon, this);

        editor.editing.view.focus();

        this.removeInputBalloonView();
        this.removeInputEditFormView();

        this.pluginUtils.hideFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
    }

    private removeInputEditFormView(): void {
        this.inputDialogView!.resetFormStatus();
        this.editor.editing.view.focus();
    }

    private removeInputBalloonView(): void {
        if (!this.userInterfaceService.isBalloonInPanel(this.balloon, this.inputBalloonView)) {
            return;
        }

        this.balloon.remove(this.inputBalloonView!);

        this.editor.editing.view.focus();
        this.pluginUtils.hideFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
    }

    private isUIInPanel(): boolean {
        return this.userInterfaceService.isBalloonInPanel(this.balloon, this.inputBalloonView) ||
                this.userInterfaceService.isBalloonInPanel(this.balloon, this.inputDialogView);
    }

    private isUIVisible(): boolean {
        return this.userInterfaceService.areActionsVisible(this.balloon, this.inputDialogView) ||
                this.userInterfaceService.areActionsVisible(this.balloon, this.inputBalloonView);
    }

    private startUpdatingUI(): void {
        const editor = this.editor;

        let previousSelectedInput = this.pluginUtils.getSelectedElementWithClass(editor, InputPlugin.MODEL_ENTITIES.container.editionView);
        let previousSelectionParent = this.getSelectionParent();

        const update = () => {
            const selectedSignature = this.pluginUtils.getSelectedElementWithClass(editor, InputPlugin.MODEL_ENTITIES.container.editionView);
            const selectionParent = this.getSelectionParent();


            if ((previousSelectedInput && !selectedSignature) ||
                (!previousSelectedInput && selectionParent !== previousSelectionParent)) {
                this.hideUI();
            } else if (this.isUIVisible()) {
                this.balloon.updatePosition( this.pluginUtils.getBalloonPositionData(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView, InputPlugin.VISUAL_SELECTION_MARKER_NAME));
            }

            previousSelectedInput = selectedSignature;
            previousSelectionParent = selectionParent;
        };

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
    }

    private getAddInputFormValidatorsAliasField(): Array<InputDialogFormViewCallback> {
        const lowercaseAlphanumericRegex  = /^[a-z0-9ñ]+$/;

        return [
            form => {
                if (form.aliasInputView.fieldView.element.value.toLocaleString() === '') {
                    return '';
                }

                if (
                    this.utilsService.hasAliasInDocument(
                        this.editor,
                        form.aliasInputView.fieldView?.element.value.toLocaleString(),
                        form.id
                    )
                ) {
                    return this.inputWarningRepeated;
                }

                if (form.aliasInputView.fieldView?.element.value.toLocaleString().length > this.MAX_ALIAS_LENGTH) {
                    return this.inputWarningLargeAlias;
                }

                if (!lowercaseAlphanumericRegex.test(form.aliasInputView.fieldView?.element.value.toLocaleString())) {
                    return this.inputWarningAllowCharacters;
                }

                return '';
            }
        ];
    }

    private getAddInputFormValidatorsHelpField(): Array<InputDialogFormViewCallback> {
        return [
            form => {
                if (form.helpInputView.fieldView?.element.value.toLocaleString().length > this.MAX_HELP_LENGTH) {
                    return this.inputWarningLargePlaceholder;
                }

                return '';
            }
        ];
    }
}
