import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isInputValueEmptyValidator } from 'src/app/core/shared/validators/inputValueEmptyValidator';

const CUSTOM_DNI_REGEX = /^\d{8}[A-Z]$/;
const DNI_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKE';
const FIRST_CHAR = 0;
const POSITION_CONTROL_LETTER = 8;

export function DNIValidator(control: AbstractControl): ValidationErrors | null {
    if (control === null || isInputValueEmptyValidator(control.value)) {
        return null;
    }

    const dniValue = control.value;

    if (!CUSTOM_DNI_REGEX.test(dniValue)) {
        return null;
    }

    return !validateDNI(dniValue) ? null : { dni: true };
}

export function DNIStringValidator(dni: string): boolean {
    if (isInputValueEmptyValidator(dni)) {
        return false;
    }

    if (!CUSTOM_DNI_REGEX.test(dni)) {
        return false;
    }

    return validateDNI(dni);
}

export function exportDNIPattern(): RegExp {
    return CUSTOM_DNI_REGEX;
}

function validateDNI(dniValue: string): boolean {
    const dniNumbers = dniValue.slice(FIRST_CHAR, POSITION_CONTROL_LETTER);
    const dniLetter = dniValue.charAt(POSITION_CONTROL_LETTER);

    const dniNumber = parseInt(dniNumbers);

    const remainder = dniNumber % DNI_LETTERS.length;
    const expectedLetter = DNI_LETTERS[remainder];

    return dniLetter.toUpperCase() === expectedLetter;
}
