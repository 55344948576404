import { Injectable } from "@angular/core";
import { Editor, ViewContainerElement, ViewElement, ViewNode, ViewDocumentFragment, Writer } from "ckeditor5";
import { InputModel } from "../../models/input/input-model";
import { InputPlugin } from "../../plugins/input/input-plugin";

@Injectable({
    providedIn: "root",
})
export class InputDataViewUtilsService {

    constructor() {}

    public getSelectionChild(editor: Editor, inputElement: any, position?: number): ViewElement | undefined {
        const focusPosition = editor.model.document.selection.focus;

        if (!focusPosition) {
            return undefined;
        }

        if (!inputElement.is('element')) {
            return undefined;
        }
        return inputElement.getChild(position);
  }

    public getSelectedInputChildrenElement(editor: Editor): ViewContainerElement | null {
        const view = editor.editing.view;
        const selection = view.document.selection;
        const selectedElement = selection.getSelectedElement();
        const childrenElement = this.getSelectionChildren(selectedElement!);
        if(!this.isInputChildrenElement(childrenElement!)) {
            return null;
        }

        return childrenElement!;
    }

    public createInputStructureModel(writer: Writer, typeInput: string, inputModel: InputModel) {
        const modelElement = this.createInputElementModel(writer, typeInput, inputModel);
        const nameModelElement = this.createInputContentElementModel(writer, inputModel);
        writer.insert(nameModelElement, modelElement, 0);

        return modelElement;
    }

    public getValue(value: string | Date | number): string {
        return value?.toLocaleString();
    }

    private createInputElementModel(writer: Writer, typeInput: string, inputModel: InputModel) {
        const name = inputModel.id;
        return writer.createElement(InputPlugin.MODEL_ENTITIES.container.model, {
            'alias': inputModel.alias,
            'input-type': typeInput,
            'is-valid': inputModel.isValid,
            'id': inputModel.id,
            'input-help': inputModel.help,
            'value': inputModel.value,
            'pattern': inputModel.pattern,
            'transform': inputModel.transform,
            'name': name
        });
    }

    private createInputContentElementModel(writer: Writer, inputModel: InputModel) {
        const nameModelElement = writer.createElement(InputPlugin.MODEL_ENTITIES.content.model);
        writer.insertText(this.getValue(inputModel.value),nameModelElement, 0);

        return nameModelElement;
    }

    private getSelectionChildren(selectedElement: ViewElement): ViewContainerElement | undefined {
        if (!selectedElement) {
            return undefined;
        }

        if (selectedElement && selectedElement.is('element')) {
            return selectedElement.getChild(0) as ViewContainerElement;
        }

        return undefined;
    }

    private isInputChildrenElement(node: ViewNode | ViewDocumentFragment): boolean {
        return (node.is("containerElement") && node.name == 'input');
    }
}
