import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isInputValueEmptyValidator } from 'src/app/core/shared/validators/inputValueEmptyValidator';

export function maxLengthValidator(maxLength: number) {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control === null || isInputValueEmptyValidator(control.value)) {
            return null;
        }

        return control.value.length < maxLength ? null : { maxLength: { requiredLength: maxLength, actualLength: control.value.length } };
    };
}

export function maxLengthStringValidator(value: string, maxLength: number): boolean {
    if (isInputValueEmptyValidator(value)) {
        return false;
    }
    return value.length < maxLength;
}
