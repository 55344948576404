import { Directive, OnDestroy } from "@angular/core";
import { ClickObserver, ContextualBalloon, Dialog, Editor, ViewDocumentClickEvent,
        ViewElement, Widget,
} from "ckeditor5";
import SignatureCommand from "../../commands/signature/add-signature-command";
import SignatureConfigComponent, {SignatureFormValidatorCallback} from "../../ui/signature/signature-balloon-form-view.directive";
import { SignatureModel } from "../../models/signature/signature-model";
import "src/css/signatureInEditor.css";
import { SignatureUtilsService } from "../../utils/signature/signature-utils.service";
import SignatureBalloonFormView from "../../ui/signature/signature-balloon-form-view.directive";
import { BasePlugin } from "../base/base-plugin";
import { SignatureSchemaService } from "../../schema/signature/signature-schema.service";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import { SignatureDataViewToModelConverterService } from "../../converters/signature/signature-data-view-to-model-converter.service";
import { SignatureModelToDataViewConverterService } from "../../converters/signature/signature-model-to-data-view-converter.service";
import { SignatureModelToEditorViewConverterService } from "../../converters/signature/signature-model-to-editor-view-converter.service";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import { UI_CLASSES } from "../../ui/styles/styles-constants";


@Directive({
    selector: "signature-plugin",
})
export class SignaturePlugin extends BasePlugin implements OnDestroy {
    public static readonly PLUGIN_NAME = "Signature";
    public static readonly SIGNATURE_IN_EDITOR = "signature";
    public static readonly SIGNATURE_CLASS = "signature-in-editor";
    public static readonly TOOLBAR_NAME_STRING = "Firma";
    public static readonly SIGNATURE_ROLE_BASE = "Firmante ";
    public static readonly SIGNATURE_SIGN_IN_PLACEMENT_CLASS = "signature-sign-placement";
    public static readonly SIGNATURE_SIGN_IN_ROL_CLASS = "signature-sign-rol";
    public static readonly SIGNATURE_CONTENT_CLASS = "signature-content";
    public static readonly SIGNATURE_ID_BASE = "signature_";
    public static readonly ID = 'id';

    protected pluginUtils: PluginUtilsService;

    public static readonly ATTRIBUTE_ROLE_IS_STORED = "signature-title";
    public static readonly MODEL_ENTITIES = {
        'class'         :   { model: SignaturePlugin.SIGNATURE_CLASS,      dataView: SignaturePlugin.SIGNATURE_IN_EDITOR,      editionView: SignaturePlugin.SIGNATURE_CLASS   },
    };

    private signatureTooltip = $localize`:@@InsertarFirmaEtiquetaBotonBotonera:Inserta hueco de firma`
    private selectRolMessage = $localize`:@@PluginFirmasTituloModal:Selecciona el rol del firmante`
    private emptyRoleMessage = $localize`:@@PluginFirmasValidacionRolVacioMensaje:El firmante no puede estar vacío`;
    private roleRepeatedMessage = $localize`:@@PluginFirmasValidacionRolenUsoMensaje:Este firmante ya está en el documento. Introduce otro.`;
    private formView: SignatureConfigComponent;
    private utilsService: SignatureUtilsService;

    private signatureSchemaService: SignatureSchemaService;
    private userInterfaceService: UserInterfaceService;

    private signatureDataViewToModelConverterService: SignatureDataViewToModelConverterService;
    private signatureModelToDataViewConverterService: SignatureModelToDataViewConverterService;
    private signatureModelToEditorViewConverterService: SignatureModelToEditorViewConverterService;

    protected mappers  = [
        SignaturePlugin.editionViewClass
    ];

    protected commands = {
        [SignaturePlugin.commandName]: SignatureCommand
    };

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.SIGNATURE,
        pluginToolbarElementName: SignaturePlugin.pluginToolbarElementName,
        buttonText: SignaturePlugin.toolbarButtonName,
        tooltip: this.signatureTooltip,
        hasTooltip: true,
        hasText: true
    };

    constructor(editor: Editor) {
        super(editor);
        this.utilsService = new SignatureUtilsService();
        this.pluginUtils = new PluginUtilsService();
        this.signatureSchemaService = new SignatureSchemaService();
        this.userInterfaceService = new UserInterfaceService();
        this.signatureDataViewToModelConverterService = new SignatureDataViewToModelConverterService();
        this.signatureModelToDataViewConverterService = new SignatureModelToDataViewConverterService();
        this.signatureModelToEditorViewConverterService = new SignatureModelToEditorViewConverterService();
    }

    public static get requires() { return [Widget, ContextualBalloon, Dialog]; }
    public static get pluginName(): string { return SignaturePlugin.PLUGIN_NAME; }
    public static get pluginModelName(): string { return SignaturePlugin.SIGNATURE_IN_EDITOR; }
    public static get pluginToolbarElementName(): string { return SignaturePlugin.SIGNATURE_IN_EDITOR; }
    public static get toolbarButtonName() { return SignaturePlugin.TOOLBAR_NAME_STRING; }
    public static get commandName() { return SignaturePlugin.SIGNATURE_IN_EDITOR; }
    public static get editionViewClass() { return SignaturePlugin.SIGNATURE_CLASS; }
    public static get visualSelectionMarker() { return SignaturePlugin.SIGNATURE_IN_EDITOR; }

    protected defineSchema(): void {
        this.signatureSchemaService.defineSchema(this.schema);
    }

    protected editorInteractions(): void {
        super.setupEditorObserver(ClickObserver);
        this.enableBalloonActivators();
    }

    protected override toolbarExecuteOperation(): void {
        const dialog = this.editor.plugins.get("Dialog");

        if (this.closeDialogIfButtonIsOn(dialog)) {
            return;
        }

        this.userInterfaceService.hideUI(this.editor, this.balloon, this.formView, SignaturePlugin.editionViewClass, this);
        const context = this;
        this.button.isOn = true;

        const defaultValue = SignaturePlugin.SIGNATURE_ROLE_BASE + (this.utilsService.getNumSignatures(this.editor) + 1).toString();

        this.formView = this.createFormView(defaultValue);
        this.setupFormView(defaultValue);
        this.handleFormEvents(dialog, defaultValue);
        this.showDialog(dialog, context);
    }

    protected defineConverters(): void {
        this.signatureDataViewToModelConverterService.configureConverters(this.editor);
        this.signatureModelToDataViewConverterService.configureConverters(this.editor);
        this.signatureModelToEditorViewConverterService.configureConverters(this.editor);
    }

    private enableBalloonActivators(): void {
        const viewDocument = this.editor.editing.view.document;
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleClickEvent.bind(this));
    }

    private handleClickEvent(): void {
        const parentSignature = this.utilsService.getSelectedSignatureElement(this.editor);

        if (!parentSignature) {
            return;
        }

        const role = this.utilsService.getRole(parentSignature);
        const signatureId = this.utilsService.getSignatureId(parentSignature);
        this.showUI(role, signatureId);
    }

    private showUI(defaultValue?: string, defaultSignatureId?: string, forceVisible: boolean = false): void {
        if (!this.formView) {
            this.createViews(defaultValue, defaultSignatureId);
        }

        const signatureElement = this.utilsService.getSelectedSignatureElement(this.editor);

        if (!signatureElement) {
            this.showFakeVisualSelection();
        }

        this.addFormView(defaultValue, defaultSignatureId);

        if (forceVisible) {
            this.balloon.showStack("main");
        }

        this.startUpdatingUI();
    }

    private createViews(defaultValue?: string, defaultSignatureId?: string): void {
        this.formView = this.createFormView(defaultValue, defaultSignatureId);
        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.formView, SignaturePlugin.visualSelectionMarker , this);
    }

    private showFakeVisualSelection(): void {
        const model = this.editor.model;

        model.change((writer) => {
            const range = model.document.selection.getFirstRange()!;
            const markerName = SignaturePlugin.visualSelectionMarker;

            if (model.markers.has(markerName)) {
                writer.updateMarker(markerName, { range });
                return;
            }

            const startPosition = range.start.isAtEnd
                ? range.start.getLastMatchingPosition(
                    ({ item }) => !model.schema.isContent(item),
                    { boundaries: range }
                )
                : range.start;

            writer.addMarker(markerName, {
                usingOperation: false,
                affectsData: false,
                range: writer.createRange(startPosition, range.end),
            });
        });
    }

    private startUpdatingUI(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        let prevSelectedSignature = this.utilsService.getSelectedSignatureElement(this.editor);
        let prevSelectionParent = getSelectionParent();

        const update = () => {
            const selectedSignature = this.utilsService.getSelectedSignatureElement(this.editor);
            const selectionParent = getSelectionParent();

            if ((prevSelectedSignature && !selectedSignature) ||
                (!prevSelectedSignature && selectionParent !== prevSelectionParent)) {
                    this.userInterfaceService.hideUI(this.editor, this.balloon, this.formView, SignaturePlugin.editionViewClass, this);
            }
            else if (this.userInterfaceService.areActionsVisible(this.balloon, this.formView)) {
                this.balloon.updatePosition(
                    this.pluginUtils.getBalloonPositionData(this.editor, SignaturePlugin.editionViewClass, SignaturePlugin.visualSelectionMarker)
                );
            }

            prevSelectedSignature = selectedSignature;
            prevSelectionParent = selectionParent;
        };

        function getSelectionParent() {
            return viewDocument.selection.focus!.getAncestors()
                .reverse()
                .find( ( node ): node is ViewElement => node.is( 'element' ) );
        }

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
    }

    private addFormView(defaultValue?: string, defaultId?: string): void {
        if (!this.formView) {
            this.createViews(defaultValue);
        }

        if (this.userInterfaceService.isBalloonInPanel(this.balloon, this.formView)) {
            const isSameSignatureEditing =
                this.formView.signatureId !== defaultId;
            if (isSameSignatureEditing) {
                this.formView!.resetFormStatus();
                this.formView.role = !!defaultValue ? defaultValue : "";
                this.formView.signatureId = defaultId;
            }
            return;
        }

        this.formView!.resetFormStatus();
        this.formView.role = !!defaultValue ? defaultValue : "";
        this.formView.signatureId = defaultId;

        this.balloon.add({
            view: this.formView!,
            position: this.pluginUtils.getBalloonPositionData(this.editor, SignaturePlugin.editionViewClass, SignaturePlugin.visualSelectionMarker),
        });

        if (this.balloon.visibleView === this.formView) {
            this.formView!.roleInputView.fieldView.select();
        }
    }

    private closeDialogIfButtonIsOn(dialog: Dialog): boolean {
        if (this.button.isOn) {
            this.closeDialog(dialog);
            return true;
        }
        return false;
    }

    private createFormView(defaultValue?: string, defaultSignatureId?: string): SignatureBalloonFormView {
        const editor = this.editor;
        const validators = this.getFormValidators(editor);
        const formView = new SignatureBalloonFormView(
            validators,
            editor.locale,
            defaultValue,
            defaultSignatureId
        );

        return formView;
    }

    private getFormValidators(editor: Editor): Array<SignatureFormValidatorCallback> {
        const t = editor.t;

        const isRoleEmpty = (form: any): string | null => {
            return !form.role || !form.role.length
                ? t(this.emptyRoleMessage)
                : null;
        };

        const isRoleInUse = (form: any): string | null => {
            return this.utilsService.hasRoleInDocument(this.editor, form.role, form.signatureId)
                ? t(this.roleRepeatedMessage)
                : null;
        };

        return [
            (form) => {
                return isRoleEmpty(form) || isRoleInUse(form) || t("");
            },
        ];
    }

    private setupFormView(defaultValue: string): void {
        this.formView = this.createFormView(defaultValue);
    }

    private handleFormEvents(dialog: Dialog, defaultValue: string): void {
        this.listenTo(this.formView, "submit", () => {
            const roleFieldView = this.formView.roleInputView;
            const role = this.formView.role;
            const id = this.formView.signatureId;

            if (!this.formView.isValid() || !role) {
                return;
            }

            if (defaultValue !== role) {
                if (!this.formView.isValid()) {
                    return;
                }
            }

            roleFieldView.errorText = "";

            const signature: SignatureModel = { id,role };
            this.editor.execute(SignaturePlugin.commandName, signature);
            this.closeDialog(dialog);
        });

        this.listenTo(this.formView, "cancel", () => {
            this.userInterfaceService.hideUI(this.editor, this.balloon, this.formView, SignaturePlugin.editionViewClass, this);
            this.closeDialog(dialog);
        });
    }

    private showDialog(dialog: any, context: this): void {
        dialog.show({
            isModal: true,
            content: this.formView,
            title: this.selectRolMessage,
            onHide: () => {
                context.button.isOn = false;
            },
            id: "",
        });
    }

    private closeDialog(dialog: Dialog): void {
        dialog.hide();
    }

}
