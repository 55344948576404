import { Injectable } from "@angular/core";
import { Editor, Node, Text, DecoupledEditor, Writer, Position, Element } from 'ckeditor5';
import { InputPlugin } from '../../plugins/input/input-plugin';
import { phoneStringValidator } from 'src/app/core/shared/validators/phoneValidator';
import { DNIStringValidator } from "src/app/core/shared/validators/DNIValidator";
import { NIFStringValidator } from "src/app/core/shared/validators/NIFValidator";
import { NIEStringValidator } from "src/app/core/shared/validators/NIEValidator";
import { IBANStringValidator } from "src/app/core/shared/validators/IBANValidator";
import { maxLengthStringValidator } from "src/app/core/shared/validators/maxLengthValidator";
import { emailStringValidator } from "src/app/core/shared/validators/emailValidator";

interface ModelElement {
    type: 'elementStart';
    item: Element;
    previousPosition: Position;
    nextPosition: Position;
    length: number;
}

@Injectable({
    providedIn: "root",
})
export class InputUtilsService {

    private MAX_LENGTH_INPUT_VALUE = 100;

    constructor() {}

    public isTypeValid(inputType: string | undefined): boolean {
        if(!inputType) {
            return false;
        }
        return InputPlugin.ALLOWED_TYPES.includes(inputType.trim());
    }

    public isDataValid(value: string, type: string): boolean {
        if(value === '') {
            return true;
        }

        switch (type)  {
            case $localize`:@@EtiquetaNoDefinido:<No definido>`:
                return maxLengthStringValidator(value, this.MAX_LENGTH_INPUT_VALUE);
            case $localize`:@@EtiquetaTelefono:Teléfono (+34 916020000)`:
                return phoneStringValidator(value);
            case $localize`:@@EtiquetaEmail:Email`:
                return emailStringValidator(value);
            case $localize`:@@EtiquetaDNI:DNI`:
                return DNIStringValidator(value);
            case $localize`:@@EtiquetaNIE:NIE`:
                return NIEStringValidator(value);
            case $localize`:@@EtiquetaNIF:NIF`:
                return NIFStringValidator(value);
            case $localize`:@@EtiquetaIBAN:IBAN`:
                return IBANStringValidator(value);
            default:
                break;
        }
    }

    public getTextContent(child: Node): string {
        return child?.is('$text') ? (child as Text).data : '';
    }

    public hasAliasInDocument(editor: Editor, alias: string, inputId?: string): boolean {
        const data = (editor as DecoupledEditor).sourceElement;

        const queryFindRoleDifferentFromCurrent = inputId
            ? `[${InputPlugin.ATTRIBUTE_ALIAS_IS_STORED}="${alias}"]:not([id="${inputId}"])`
            : `[${InputPlugin.ATTRIBUTE_ALIAS_IS_STORED}="${alias}"]`;

        return data?.querySelector(queryFindRoleDifferentFromCurrent) !== null;
    }

    public getInputValue(inputModel: Element): string{
        for (const child of inputModel.getChildren()) {
            if (child.is('$text')) {
                return child.data;
            } else {
                const text = this.getInputValue(child as Element);
                if (text) {
                    return text;
                }
            }
        }
        return null;
    }

    public transformInputValue(writer: Writer,  inputModel: ModelElement, transform: string): void {
        const previousValue = this.getInputValue(inputModel.item);
        if (!previousValue) {
            return;
        }

        const inputcontentModel = (inputModel.item as Element).getChild(0) as Element;
        const range = writer.createRangeIn(inputcontentModel);
        writer.remove(range);
        writer.insertText(this.transformText(previousValue, transform), inputcontentModel, 0);
    }

    public transformText(text: string, transform: string): string {
        if(text.trim() === '') {
            return '';
        }

        const transformations: { [key: string]: () => string } = {
            [$localize`:@@EtiquetaMayusculas:Mayúsculas`]: () => text.toUpperCase(),
            [$localize`:@@EtiquetaMinusculas:Minúsculas`]: () => text.toLowerCase(),
            [$localize`:@@EtiquetaCapitales:Capitales`]: () =>
                text.toLowerCase().replace(/(^|\s)([a-zA-ZÀ-ÿ])/g, char => char.toUpperCase())
        };
        return transformations[transform]?.() || text;
    }
}
