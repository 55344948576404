import { Editor, Element, Writer } from "ckeditor5";
import { InputModel } from "../../models/input/input-model";
import { InputBaseCommand } from "./input-base-command";
import { InputPlugin } from "../../plugins/input/input-plugin";

export default class EditInputCommand extends InputBaseCommand {

    constructor(editor: Editor){
        super(editor);
    }

    public override execute(inputModel: InputModel): void {
        const editor = this.editor;
        if (!inputModel.id) {
            return;
        }

        editor.model.change( (writer: Writer) => {
            const range = writer.createRangeIn(this.editor.model.document.getRoot()!);
            const inputElement = this.getElementWithId(range, inputModel.id!)?.item as Element;
            if(inputElement) {
                this.setInputAttributes(writer, inputModel, inputElement);
            }
        });
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.enableCommandIfCursorInsideInputContent();
        }
    }

    private setInputAttributes(writer: Writer, inputModel: InputModel, inputElement: Element): void {
        const attributes = {
            [InputPlugin.MODEL_ENTITIES.alias.model]: inputModel.alias,
            [InputPlugin.MODEL_ENTITIES.help.model]: inputModel.help,
            [InputPlugin.MODEL_ENTITIES.type.model]: inputModel.type,
            [InputPlugin.MODEL_ENTITIES.pattern.model]: inputModel.pattern,
            [InputPlugin.MODEL_ENTITIES.transform.model]: inputModel.transform,
            [InputPlugin.MODEL_ENTITIES.isValid.model]: true
        };

        for (const [key, value] of Object.entries(attributes)) {
            writer.setAttribute(key, value, inputElement);
        }

        writer.setAttribute(InputPlugin.MODEL_ENTITIES.help.model, inputModel.help, inputElement.getChild(0));
    }
}
