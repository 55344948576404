import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { GlobalConstant } from "../../models/base/global-constant";
import { InputPlugin } from "../../plugins/input/input-plugin";

@Injectable({
    providedIn: 'root'
})
export class InputSchemaService {

    constructor() { }

    public defineSchema(schema: Schema): void {
        this.defineInputContainer(schema);
        this.defineInputContent(schema);
    }

    private defineInputContainer(schema): void {
        schema.register(InputPlugin.MODEL_ENTITIES.container.model, {
            inheritAllFrom: '$inlineObject',
            allowAttributes: [
                InputPlugin.MODEL_ENTITIES.type.model,
                InputPlugin.MODEL_ENTITIES.isValid.model,
                GlobalConstant.ATTRIBUTE_ID,
                InputPlugin.MODEL_ENTITIES.help.model,
                GlobalConstant.ATTRIBUTE_VALUE,
                InputPlugin.MODEL_ENTITIES.pattern.model,
                InputPlugin.MODEL_ENTITIES.transform.model,
                InputPlugin.MODEL_ENTITIES.alias.model
            ]
        });

        this.defineInputContainerValidations(schema);
    }

    private defineInputContent(schema: Schema): void {
        schema.register(InputPlugin.MODEL_ENTITIES.content.model, {
            allowIn: InputPlugin.MODEL_ENTITIES.container.model,
            allowChildren: '$text',
            isLimit: true,
            allowAttributes: [
                InputPlugin.MODEL_ENTITIES.help.model
            ]
        });

        this.defineInputContentValidations(schema);
    }

    private defineInputContentValidations(schema: Schema): void {
        schema.addAttributeCheck(context => {
            if (context.endsWith(`${InputPlugin.MODEL_ENTITIES.content.model} $text`)) {
                return false;
            }

            return;
        });
    }

    private defineInputContainerValidations(schema: any): void {
        schema.addChildCheck((context, childDefinition) => {
            if (context.endsWith(InputPlugin.MODEL_ENTITIES.content.model) && (childDefinition.name !== '$text')) {
                return false;
            }
            return;
        });
    }
}
