import { Editor, Node, Element, Conversion, ModelConsumable } from 'ckeditor5';
import { Injectable } from "@angular/core";
import { InputUtilsService } from '../../utils/input/input-utils.service';
import { InputPlugin } from '../../plugins/input/input-plugin';
import { GlobalConstant } from '../../models/base/global-constant';

@Injectable({
    providedIn: "root",
})
export class InputModelToDataViewConverterService {

    private utilsService: InputUtilsService;

    constructor() {
        this.utilsService = new InputUtilsService();
    }

    public configureConverter(editor: Editor): void {
        const conversion = editor.conversion;

        this.inputConversion(editor);
        this.isValidAttributeConversion(conversion);
    }

    private inputConversion(editor: Editor): Conversion {
        const conversion = editor.conversion;
        conversion.for('dataDowncast').elementToElement({
            model: InputPlugin.MODEL_ENTITIES.container.model,
            view: (modelElement: Element, { writer, consumable }) => {

                const child = this.getContentElement(editor, modelElement, consumable);
                const valueInInput = this.utilsService.getTextContent(child);

                const is_valid = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.isValid.model) === true;
                const id = modelElement.getAttribute(GlobalConstant.ATTRIBUTE_ID);
                const alias = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.alias.model);
                const helpNote = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.help.model);
                const pattern = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.pattern.model);
                const transform = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.transform.model);

                return writer.createContainerElement('input', {
                    'type': modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.type.model),
                    value: valueInInput,
                    'data-is-valid': is_valid,
                    'id': id,
                    'name': id,
                    'data-alias': alias,
                    'data-input-help': helpNote,
                    'data-pattern': pattern,
                    'data-transform': transform
                });
            }
        });
        return conversion;
    }

    private isValidAttributeConversion(conversion: Conversion): void {
        conversion.for('dataDowncast').attributeToAttribute({
            model: InputPlugin.MODEL_ENTITIES.isValid.model,
            view: InputPlugin.MODEL_ENTITIES.isValid.editionView
        });
    }

    private getContentElement(editor: Editor, modelElement: Element, consumable: ModelConsumable): Node {
        let nameModelElement: Node | null = null;

        for (const { item } of editor.model.createRangeIn(modelElement)) {
            if (item.is('element', InputPlugin.MODEL_ENTITIES.content.model)) {
                nameModelElement = modelElement.getChild(0)!;
            }
            consumable.consume(item, 'insert');
        }

        const child = !!nameModelElement && (nameModelElement as Element).getChild(0) ? (nameModelElement as Element).getChild(0) : null;
        return child;
    }
}
