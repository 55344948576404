export class InputModel {
    id?: string;
    name?: string;
    help?: string;
    type?: string;
    value?: string | Date | number;
    alias?: string;
    pattern?: string;
    transform?: string;
    isValid?: boolean;
}
