import { Injectable } from "@angular/core";
import { Editor, ViewContainerElement, ViewElement, ViewNode, ViewDocumentFragment, ViewText, Text } from "ckeditor5";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import { InputPlugin } from "../../plugins/input/input-plugin";

@Injectable({
    providedIn: "root",
})
export class InputEditionViewUtilsService {

   private pluginUtils: PluginUtilsService;

    constructor() {
        this.pluginUtils = new PluginUtilsService();
    }

    public getInputValue(parentInputElement: ViewElement): string {
        const nameModelElement = parentInputElement.getChild(0)!;
        const child = !!nameModelElement && (nameModelElement as ViewElement).getChild(0) ? (nameModelElement as ViewElement).getChild(0) : null;

        let valueInInput = '';
        if(!!child && child.is('$text')) {
            valueInInput = (child as ViewText).data;
        }

        return valueInInput;
    }

    public getSelectedContainInputElement(editor: Editor): ViewContainerElement | null {
        return this.pluginUtils.getSelectedContainerWithClass(editor, InputPlugin.MODEL_ENTITIES.container.editionView);
    }

    public getSelectionChild(editor: Editor, inputElement: any, position?: number): ViewElement | undefined {
        const focusPosition = editor.model.document.selection.focus;

        if (!focusPosition) {
            return undefined;
        }

        if (inputElement.is('element')) {
            return inputElement.getChild(position);
        }

        return undefined;
  }

    public getSelectedInputChildrenElement(editor: Editor): ViewContainerElement | null {
        const view = editor.editing.view;
        const selection = view.document.selection;
        const selectedElement = selection.getSelectedElement();
        const childrenElement = this.getSelectionChildren(selectedElement!);
        if(this.isInputChildrenElement(childrenElement!)) {
            return childrenElement!;
        }
        return null;
    }

    private getSelectionChildren(selectedElement: ViewElement): ViewContainerElement | undefined {
        if (!selectedElement) {
            return undefined;
        }

        if (selectedElement && selectedElement.is('element')) {
            return selectedElement.getChild(0) as ViewContainerElement;
        }

        return undefined;
    }

    private isInputChildrenElement(node: ViewNode | ViewDocumentFragment): boolean {
        return (node.is("containerElement") && node.name == 'input');
    }
}
