import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isInputValueEmptyValidator } from 'src/app/core/shared/validators/inputValueEmptyValidator';

const CUSTOM_NIF_REGEX = /^[ABCDEFGHJKLMNPQRSUVW0-9]\d{7}[A-Za-z\d]$/;
const NIF_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKE';
const FIRST_CHAR = 0;
const POSITION_CONTROL_LETTER = 8;

export function NIFValidator(control: AbstractControl): ValidationErrors | null {
    if (control === null || isInputValueEmptyValidator(control.value)) {
        return null;
    }

    const nifValue = control.value

    if (!CUSTOM_NIF_REGEX.test(nifValue)) {
        return null;
    }

    return !validateNIF(nifValue) ? null : { nif: true };
}

export function NIFStringValidator(nif: string): boolean {
    if (isInputValueEmptyValidator(nif)) {
        return false;
    }

    if (!CUSTOM_NIF_REGEX.test(nif)) {
        return false;
    }

    return validateNIF(nif);
}

export function exportNIFPattern(): RegExp {
    return CUSTOM_NIF_REGEX;
}

function validateNIF(nifValue: string): boolean {
    let nifNumbers;
    if (NIF_LETTERS.includes(nifValue.charAt(FIRST_CHAR))) {
        nifNumbers = nifValue.slice(FIRST_CHAR + 1, POSITION_CONTROL_LETTER);
    } else {
        nifNumbers = nifValue.slice(FIRST_CHAR, POSITION_CONTROL_LETTER);
    }
    const nifControlLetter = nifValue.charAt(POSITION_CONTROL_LETTER);

    const nifNumber = parseInt(nifNumbers);
    const remainder = nifNumber % NIF_LETTERS.length;
    const expectedLetter = NIF_LETTERS.charAt(remainder);

    return nifControlLetter.toUpperCase() === expectedLetter || !isNaN(parseInt(nifControlLetter));
}
