import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isInputValueEmptyValidator } from 'src/app/core/shared/validators/inputValueEmptyValidator';

const CUSTOM_NIE_REGEX = /^[XYZ]\d{7}[A-Za-z]$/;
const NIE_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKE';
const FIRST_CHAR = 0
const POSITION_CONTROL_LETTER = 8

export function NIEValidator(control: AbstractControl): ValidationErrors | null {
    if (control === null || isInputValueEmptyValidator(control.value)) {
        return null;
    }

    const nieValue = control.value

    if (!CUSTOM_NIE_REGEX.test(nieValue)) {
        return null;
    }

    return !validateNIE(nieValue) ? null : { nie: true };
}

export function NIEStringValidator(nie: string): boolean {
    if (isInputValueEmptyValidator(nie)) {
        return false;
    }

    if (!CUSTOM_NIE_REGEX.test(nie)) {
        return false;
    }

    return validateNIE(nie);
}

export function exportNIEPattern(): RegExp {
    return CUSTOM_NIE_REGEX;
}

function validateNIE(nieValue: string): boolean {
    const nieFirstLetter = nieValue.charAt(FIRST_CHAR);
    const nieNumbers = nieValue.slice(FIRST_CHAR + 1, POSITION_CONTROL_LETTER);
    const nieControlLetter = nieValue.charAt(POSITION_CONTROL_LETTER);
    const letterMap: { [key: string]: string } = { 'X': '0', 'Y': '1', 'Z': '2' };
    const firstLetterValue = letterMap[nieFirstLetter];

    const fullNumber = parseInt(firstLetterValue + nieNumbers, 10);

    const remainder = fullNumber % NIE_LETTERS.length;
    const expectedLetter = NIE_LETTERS.charAt(remainder);

    return nieControlLetter.toUpperCase() === expectedLetter;
}
