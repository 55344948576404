export const UI_CLASSES = {
    BUTTONS: {
        SAVE: 'ck-button-save',
        PLUGIN: 'rb-plugin',
        CANCEL: 'ck-button-cancel'
    },
    SVG_ICONS: {
        CHECK_ICON: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M355.3 134.3L267.9 248.7l-49.34-43.5C211.9 199.3 201.8 199.1 196 206.6C190.2 213.2 190.8 223.3 197.4 229.2L259.6 284C262.6 286.6 266.3 288 270.2 288c.5313 0 1.206 .0187 1.737-.0125c4.406-.4375 8.294-2.737 10.98-6.269l97.78-128c5.375-7.031 4.031-17.06-3-22.44C370.7 125.9 360.7 127.2 355.3 134.3zM528 320c-8.844 0-16 7.156-16 16s7.156 16 16 16c8.812 0 16 7.172 16 16v64c0 8.828-7.188 16-16 16h-480C39.19 448 32 440.8 32 432v-64C32 359.2 39.19 352 48 352C56.84 352 64 344.8 64 336S56.84 320 48 320C21.53 320 0 341.5 0 368v64C0 458.5 21.53 480 48 480h480c26.47 0 48-21.53 48-48v-64C576 341.5 554.5 320 528 320zM80 416h416c8.836 0 16-7.164 16-16S504.8 384 496 384H480V80C480 53.49 458.5 32 432 32h-288C117.5 32 96 53.49 96 80V384H80C71.16 384 64 391.2 64 400S71.16 416 80 416zM128 80C128 71.16 135.2 64 144 64h288C440.8 64 448 71.16 448 80V384H128V80z"/></svg>',
        CALENDAR_DATE: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 264C64 241.9 81.91 224 104 224H184C206.1 224 224 241.9 224 264V344C224 366.1 206.1 384 184 384H104C81.91 384 64 366.1 64 344V264zM96 264V344C96 348.4 99.58 352 104 352H184C188.4 352 192 348.4 192 344V264C192 259.6 188.4 256 184 256H104C99.58 256 96 259.6 96 264zM128 64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0C120.8 0 128 7.164 128 16V64zM32 448C32 465.7 46.33 480 64 480H384C401.7 480 416 465.7 416 448V192H32V448zM32 128V160H416V128C416 110.3 401.7 96 384 96H64C46.33 96 32 110.3 32 128z"/></svg>',
        ICON_INFO: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480zM256 184c13.25 0 24-10.74 24-24c0-13.25-10.75-24-24-24S232 146.7 232 160C232 173.3 242.7 184 256 184zM304 352h-32V240C272 231.2 264.8 224 256 224H224C215.2 224 208 231.2 208 240S215.2 256 224 256h16v96h-32C199.2 352 192 359.2 192 368C192 376.8 199.2 384 208 384h96c8.836 0 16-7.164 16-16C320 359.2 312.8 352 304 352z"/></svg>',
        ICON_INPUT: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M176 160C182.5 160 188.4 163.1 190.9 170.1L254.9 330.1C258.1 338.3 254.1 347.6 245.9 350.9C237.7 354.1 228.4 350.1 225.1 341.9L216.4 319.1L216 320H136L135.6 319.1L126.9 341.9C123.6 350.1 114.3 354.1 106.1 350.9C97.85 347.6 93.86 338.3 97.14 330.1L161.1 170.1C163.6 163.1 169.5 160 176 160H176zM148.4 288H203.6L176 219.1L148.4 288zM288 176C288 167.2 295.2 160 304 160H360C390.9 160 416 185.1 416 216C416 228.1 412.2 239.3 405.6 248.5C421.5 258.4 432 275.1 432 296C432 326.9 406.9 352 376 352H304C295.2 352 288 344.8 288 336V176zM320 272V320H376C389.3 320 400 309.3 400 296C400 282.7 389.3 272 376 272H320zM360 240C373.3 240 384 229.3 384 216C384 202.7 373.3 192 360 192H320V240H360zM0 128C0 92.65 28.65 64 64 64H576C611.3 64 640 92.65 640 128V384C640 419.3 611.3 448 576 448H64C28.65 448 0 419.3 0 384V128zM32 128V384C32 401.7 46.33 416 64 416H576C593.7 416 608 401.7 608 384V128C608 110.3 593.7 96 576 96H64C46.33 96 32 110.3 32 128z"/></svg>',
        ICON_SHORT_TEXT: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#8B4513"><path d="M132-466v-28h696v28H132Zm174-120v-68h348v68H306Zm0 280v-68h348v68H306Z"/></svg>',
        ICON_LONG_TEXT: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#8B4513"><path d="M172-172v-28h616v28H172Zm160-147v-28h296v28H332ZM172-466v-28h616v28H172Zm160-147v-28h296v28H332ZM172-760v-28h616v28H172Z"/></svg>',
        GO_TO: '<svg xmlns="http://www.w3.org/2000/svg" id="square-list" viewBox="0 0 448 512"> <path d="M128 184C114.7 184 104 173.3 104 160C104 146.7 114.7 136 128 136C141.3 136 152 146.7 152 160C152 173.3 141.3 184 128 184zM192 160C192 151.2 199.2 144 208 144H336C344.8 144 352 151.2 352 160C352 168.8 344.8 176 336 176H208C199.2 176 192 168.8 192 160zM192 256C192 247.2 199.2 240 208 240H336C344.8 240 352 247.2 352 256C352 264.8 344.8 272 336 272H208C199.2 272 192 264.8 192 256zM192 352C192 343.2 199.2 336 208 336H336C344.8 336 352 343.2 352 352C352 360.8 344.8 368 336 368H208C199.2 368 192 360.8 192 352zM128 232C141.3 232 152 242.7 152 256C152 269.3 141.3 280 128 280C114.7 280 104 269.3 104 256C104 242.7 114.7 232 128 232zM128 376C114.7 376 104 365.3 104 352C104 338.7 114.7 328 128 328C141.3 328 152 338.7 152 352C152 365.3 141.3 376 128 376zM0 96C0 60.65 28.65 32 64 32H384C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96zM32 96V416C32 433.7 46.33 448 64 448H384C401.7 448 416 433.7 416 416V96C416 78.33 401.7 64 384 64H64C46.33 64 32 78.33 32 96z"></path> </svg>',
        CLAUSE_EDITOR_ICON: '<svg xmlns="http://www.w3.org/2000/svg" id="square-list" viewBox="0 0 448 512"> <path d="M128 184C114.7 184 104 173.3 104 160C104 146.7 114.7 136 128 136C141.3 136 152 146.7 152 160C152 173.3 141.3 184 128 184zM192 160C192 151.2 199.2 144 208 144H336C344.8 144 352 151.2 352 160C352 168.8 344.8 176 336 176H208C199.2 176 192 168.8 192 160zM192 256C192 247.2 199.2 240 208 240H336C344.8 240 352 247.2 352 256C352 264.8 344.8 272 336 272H208C199.2 272 192 264.8 192 256zM192 352C192 343.2 199.2 336 208 336H336C344.8 336 352 343.2 352 352C352 360.8 344.8 368 336 368H208C199.2 368 192 360.8 192 352zM128 232C141.3 232 152 242.7 152 256C152 269.3 141.3 280 128 280C114.7 280 104 269.3 104 256C104 242.7 114.7 232 128 232zM128 376C114.7 376 104 365.3 104 352C104 338.7 114.7 328 128 328C141.3 328 152 338.7 152 352C152 365.3 141.3 376 128 376zM0 96C0 60.65 28.65 32 64 32H384C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96zM32 96V416C32 433.7 46.33 448 64 448H384C401.7 448 416 433.7 416 416V96C416 78.33 401.7 64 384 64H64C46.33 64 32 78.33 32 96z"></path> </svg>',
        SIGNATURE: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M560.9 136L536 111.1c-20.25-20.25-53-20.25-73.25 0L303.5 269.8C273.4 299.8 256.1 341.3 256 383.8c-3.875-.25-7.5-2.387-9.375-6.262c-11.88-23.88-46.25-30.38-66-14.12l-13.88-41.63C163.5 311.9 154.4 305.3 144 305.3s-19.5 6.625-22.75 16.5L103 376.4C101.5 381 97.25 384 92.38 384H80C71.13 384 64 391.1 64 400S71.13 416 80 416h12.4c18.62 0 35.1-11.88 40.98-29.5L144 354.6L160.8 405C165.4 418.6 184 419.9 190.3 407.1l7.749-15.38c4-8 15.69-8.5 20.19 .375C225.4 406.6 239.9 415.7 256 415.9L288 416c66.84 0 112.1-46.3 114.1-47.5l158.8-159.3C570.6 199.5 576 186.4 576 172.6C576 158.8 570.6 145.8 560.9 136zM379.5 346C355.3 370.3 322.4 383.9 288 383.9c0-34.38 13.75-67.32 37.1-91.44l120.6-119.9l52.75 52.75L379.5 346zM538.3 186.6L517 207.8L464.3 155l21.12-21.25c7.75-7.625 20.25-7.749 28 0l24.88 24.88C545.9 166.4 545.9 178.9 538.3 186.6zM364.4 448c-6.629 0-13.1 3.795-15.2 10.1C344.1 470.8 333 480 318.1 480h-255.2c-17.62 0-31.89-14.33-31.89-32V64c0-17.67 14.28-32 31.89-32h127.6v112c0 26.51 21.42 48 47.84 48h64.07c8.652 0 15.66-7.037 15.66-15.72V175.7C318.1 167 311.9 160 303.3 160H239.2C230.4 160 223.3 152.8 223.3 144V34.08c4.461 1.566 8.637 3.846 12.08 7.299l89.85 90.14c6.117 6.139 16.04 6.139 22.15 0L347.7 131.1c6.117-6.139 6.117-16.09 0-22.23L257.9 18.75C245.9 6.742 229.7 0 212.8 0H63.93C28.7 0 0 28.65 0 64l.0065 384c0 35.35 28.7 64 63.93 64h255c28.2 0 52.12-18.36 60.55-43.8C382.8 458.2 374.9 448 364.4 448z"/></svg>',
        RADIO_TOOLBAR_ICON_SVG: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480.23-313.66q69.58 0 117.85-48.49 48.26-48.5 48.26-118.08t-48.49-117.85q-48.5-48.26-118.08-48.26t-117.85 48.49q-48.26 48.5-48.26 118.08t48.49 117.85q48.5 48.26 118.08 48.26Zm.14 189.58q-73.43 0-138.34-27.82-64.92-27.83-113.66-76.6-48.73-48.77-76.51-113.51-27.78-64.74-27.78-138.36 0-73.69 27.82-138.1 27.83-64.42 76.6-113.16 48.77-48.73 113.51-76.51 64.74-27.78 138.36-27.78 73.69 0 138.1 27.82 64.42 27.83 113.16 76.6 48.73 48.77 76.51 113.28 27.78 64.51 27.78 137.85 0 73.43-27.82 138.34-27.83 64.92-76.6 113.66-48.77 48.73-113.28 76.51-64.51 27.78-137.85 27.78Zm-.38-47.96q127.89 0 217.93-90.02 90.04-90.03 90.04-217.93 0-127.89-90.02-217.93-90.03-90.04-217.93-90.04-127.89 0-217.93 90.02-90.04 90.03-90.04 217.93 0 127.89 90.02 217.93 90.03 90.04 217.93 90.04ZM480-480Z"/></svg>',
        EDIT: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M495 59.6C516.9 81.47 516.9 116.9 495 138.8L182.3 451.6C170.9 462.9 156.9 471.2 141.5 475.8L20.52 511.4C14.9 513 8.827 511.5 4.687 507.3C.5466 503.2-1.002 497.1 .6506 491.5L36.23 370.5C40.76 355.1 49.09 341.1 60.44 329.7L373.2 16.97C395.1-4.897 430.5-4.897 452.4 16.97L495 59.6zM341 94.4L417.6 170.1L472.4 116.2C481.8 106.8 481.8 91.6 472.4 82.23L429.8 39.6C420.4 30.23 405.2 30.23 395.8 39.6L341 94.4zM318.4 117L83.07 352.4C75.5 359.9 69.95 369.3 66.93 379.6L39.63 472.4L132.4 445.1C142.7 442.1 152.1 436.5 159.6 428.9L394.1 193.6L318.4 117z"/></svg>',
        DELETE: '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="24px" viewBox="0 0 24 24"><path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"></path></svg>'
    },
    SIZE: {
        WIDTH_CLAUSE_MODAL: '50vw',
        HEIGHT_CLAUSE_MODAL: '90vh'
    }
};
