import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isInputValueEmptyValidator } from 'src/app/core/shared/validators/inputValueEmptyValidator';

const CUSTOM_IBAN_REGEX = /^[A-Z]{2}\d{2}([A-Z0-9]{0,30})$/;

export function IBANValidator(control: AbstractControl): ValidationErrors | null {
    if (control === null || isInputValueEmptyValidator(control.value)) {
        return null;
    }

    return CUSTOM_IBAN_REGEX.test(control.value) ? null : { iban: true};
}

export function IBANStringValidator(iban: string): boolean {
    if (isInputValueEmptyValidator(iban)) {
        return false;
    }

    return CUSTOM_IBAN_REGEX.test(iban);;
}

export function exportIBANPattern(): RegExp {
    return CUSTOM_IBAN_REGEX;
}
