import { Directive, Inject } from '@angular/core';
import {
    LabeledFieldView, ButtonView, Locale, InputNumberView, createLabeledInputNumber, LabelView
} from 'ckeditor5';
import FormRowView from '../../../utils/form-row-view.directive';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import BaseView from '../base-view.directive';
import { UI_CLASSES } from '../styles/styles-constants'

@Directive({
    selector: 'app-edit-radio-dialog-form-view',
})
export default class EditRadioDialogFormView extends BaseView {

    public optionsCountInputView: LabeledFieldView<InputNumberView>;

    private titleRowLabel: LabelView;
    private removeButtonView: ButtonView;
    private saveButtonView: ButtonView;
    private cancelButtonView: ButtonView;

    private initialOptionsCountValue: number;
    private oldStoredValue = '2';
    private radioId = '';

    private readonly validators: Array<EditRadioFormValidatorCallback>;

    private readonly optionsNumberMessage = $localize`:@@NumeroOptionesGrupoRadioPlugin:N.º de opciones para este grupo`;
    private readonly removeGroupMessage = $localize`:@@EliminarGrupoRadioPlugin:Eliminar grupo`;
    private readonly notNullGroupsMessage = $localize`:@@NumeroGrupoNuloRadioPlugin:No puede ser nulo`;

    constructor(
        @Inject(Array<EditRadioFormValidatorCallback>) validators: Array<EditRadioFormValidatorCallback>,
        @Inject(Locale) locale?: Locale,
        @Inject(String) radioId?: string,
        @Inject(Number) optionsCount?: number
    ) {
        super(locale);

        this.radioId = radioId!;
        this.validators = validators;

        this.radioId = radioId!;
        this.validators = validators;

        this.initializeViews(locale, optionsCount);
        this.setupListeners();
        this.setupForm(locale);
    }

    public get optionsCount(): number | null {
        const { element } = this.optionsCountInputView.fieldView;

        if (!element) {
            return null;
        }

        return parseInt(element.value);
    }

    public get initialOptionsCount(): number | null {
        return this.initialOptionsCountValue;
    }

    public set optionsCount(value: number | null) {
        const { element } = this.optionsCountInputView.fieldView;

        if (!element) {
            return;
        }

        element.value = value?.toString()!;
        this.initialOptionsCountValue = value!;
    }

    public get id(): string {
        return this.radioId;
    }

    public set id(value: string) {
        this.radioId = value;
    }

    public selectDefaultField() {
        this.optionsCountInputView.fieldView.select();
    }

    public isValid(): boolean {
        this.resetFormStatus();

        for (const validator of this.validators) {
            const errorText = validator(this);

            if (errorText) {
                this.optionsCountInputView.errorText = errorText;

                return false;
            }
        }

        return true;
    }

    public resetFormStatus(): void {
        this.optionsCountInputView.errorText = null;
    }

    private initializeViews(locale?: Locale, optionsCount?: number): void {
        this.titleRowLabel = new LabelView(locale);
        this.optionsCountInputView = this.createOptionsCountInput(this.optionsNumberMessage, optionsCount?.toString());
        this.removeButtonView =  this.createButton(this.removeGroupMessage, "ck-button-delete", UI_CLASSES.SVG_ICONS.DELETE);
        this.saveButtonView = this.createButton(this.submitButtonMessage, `ck-button-save ${UI_CLASSES.BUTTONS.PLUGIN}`);
        this.saveButtonView.type = 'submit';
        this.cancelButtonView = this.createButton(this.cancelButtonMessage, `ck-button-cancel ${UI_CLASSES.BUTTONS.PLUGIN}`);
        this.cancelButtonView.delegate('execute').to(this, 'cancel');
    }

    private setupListeners(): void {
        this.listenTo(this.removeButtonView, 'execute', () => {
            const { element } = this.optionsCountInputView.fieldView;
            if (!element) {
                return;
            }
            element.value = "0";
        });
    }

    private setupForm(locale?: Locale): void {
        const rowOptions = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.titleRowLabel,
                this.optionsCountInputView,
                this.removeButtonView
            ]
        });

        this.items = this.createCollection([
            this.titleRowLabel,
            rowOptions,
            this.saveButtonView,
            this.cancelButtonView
        ]);

        this.setTemplateForm("ck-radio-form");
    }

    private createOptionsCountInput(label: string, defaultValue?: string): LabeledFieldView<InputNumberView> {
        const labeledInput = new LabeledFieldView(this.locale, createLabeledInputNumber);

        labeledInput.label = label;
        labeledInput.fieldView.value = defaultValue?.toString();
        labeledInput.fieldView.min = 0;
        labeledInput.fieldView.max = RadioPlugin.MAX_RADIOS;
        this.oldStoredValue = defaultValue!;

        labeledInput.fieldView.on('input', (value: any) => {
            if (labeledInput.fieldView.element?.value === '1') {
                if (this.oldStoredValue === '2') {
                    labeledInput.fieldView.element.value = '0';
                } else {
                    labeledInput.fieldView.element.value = '2';
                }
            }

            this.oldStoredValue = labeledInput.fieldView.element!.value;
            labeledInput.errorText = !!value?.source?.value ? '' : this.notNullGroupsMessage;
        });

        return labeledInput;
    }
}

export type EditRadioFormValidatorCallback = (form: EditRadioDialogFormView) => string | undefined;
