import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isInputValueEmptyValidator } from 'src/app/core/shared/validators/inputValueEmptyValidator';

const VALID_CHARACTERS_REGEX = /[^a-zA-Z0-9äëïöüÄËÏÖÜñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ+@#$&/()Çç.:,`'´ªº\\\-%¿?!¡€^\s_]+/g;

function validateRegexExec(word: string, regularExpression: RegExp): string {
    let notMatchRegEx: string[] = [];
    let execResult: RegExpExecArray;
    regularExpression.lastIndex = 0;

    do {
        execResult = regularExpression.exec(word);

        if (execResult) {
            notMatchRegEx.push(execResult[0]);
        }

    } while (execResult);

    if (notMatchRegEx !== null) {
        return notMatchRegEx.join();
    }

    return null;
}

export function invalidCharactersValidator(control: AbstractControl): ValidationErrors | null {
    if (control === null) {
        return null;
    }

    const invalidCharacters = getInvalidCharacters(control.value);

    if (invalidCharacters !== null && invalidCharacters !== '') {
        return { 'invalidCharacters': invalidCharacters };
    }

    return null;
}

export function getInvalidCharacters(value: string): string | null {
    if (isInputValueEmptyValidator(value)) {
        return null;
    }

    const invalidCharacters = value !== undefined ? validateRegexExec(value, VALID_CHARACTERS_REGEX) : null;

    if (invalidCharacters !== null && invalidCharacters !== '') {
        return invalidCharacters;
    }

    return null;
}

