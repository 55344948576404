import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isInputValueEmptyValidator } from 'src/app/core/shared/validators/inputValueEmptyValidator';

const CUSTOM_PHONE_REGEX = /^\+\d{1,3} \d(?: ?\d){5,15}$/;

export function phoneValidator(control: AbstractControl): ValidationErrors | null {
    if (control === null || isInputValueEmptyValidator(control.value)) {
        return null;
    }

    return CUSTOM_PHONE_REGEX.test(control.value) ? null : { phone: true };
}

export function phoneStringValidator(phone: string): boolean {
    if (isInputValueEmptyValidator(phone)) {
        return false;
    }

    return CUSTOM_PHONE_REGEX.test(phone);;
}

export function exportPhonePattern(): RegExp {
    return CUSTOM_PHONE_REGEX;
}
