import { Injectable } from '@angular/core';
import { Conversion, Element, toWidget, toWidgetEditable } from 'ckeditor5';
import { GlobalConstant } from '../../models/base/global-constant';
import { OptionalFragmentCheckPlugin } from '../../plugins/optional-fragment-check/optional-fragment-check-plugin';

@Injectable({
    providedIn: 'root'
})
export class FragmentCheckModelToEditorViewConverterService {

    public configureConverters(conversion: Conversion): void {
        this.containerConversion(conversion);
        this.checkConversion(conversion);
        this.descriptionConversion(conversion);
        this.contentConversion(conversion);
    }

    private containerConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const id = modelItem.getAttribute(GlobalConstant.ATTRIBUTE_ID);
                const element = viewWriter.createContainerElement(
                    GlobalConstant.LABEL_DIV,
                    {
                        id,
                    });
                    viewWriter.addClass([OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.editionView, GlobalConstant.CONTAINER_CLASS_EDITION_VIEW], element);
                return toWidget(element, viewWriter);
            },
        });
    }

    private checkConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: {
                name: OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.model,
                attributes: [OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.model]
            },
            view: (modelItem: Element, { writer: viewWriter }) => {
                const id = modelItem.getAttribute('id');
                const isChecked = !!modelItem.getAttribute(OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.model);

                const viewElement = viewWriter.createContainerElement('input', {
                    class: OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.editionView,
                    id,
                    type: 'checkbox',
                    ...(isChecked && { checked: true })  // Conditionally add the 'checked' attribute
                });

                return toWidget(viewElement, viewWriter);
            },
        });
    }

    private descriptionConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                if(modelItem.parent.name !== OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.model) {
                    return;
                }

                const widgetElement = viewWriter.createEditableElement("div", {
                    class: [OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.editionView],
                });

                return toWidgetEditable(widgetElement, viewWriter);
            },
        });
    }

    private contentConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.editionView,
            view: (_modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = viewWriter.createEditableElement("div", {
                    class: [OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.editionView],
                });

                return toWidgetEditable(widgetElement, viewWriter);
            },
        });
    }
}
