import { GlobalConstant } from '../../models/base/global-constant';
import { OptionalFragmentCheckPlugin } from '../../plugins/optional-fragment-check/optional-fragment-check-plugin';
import { Editor, Writer } from 'ckeditor5';
import { OptionalFragmentCheckCommand } from './optional-fragment-check-command';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';

export default class AddOptionalFragmentCheckCommand extends OptionalFragmentCheckCommand {

    private readonly helpTextOptionMessage = $localize`:@@TextoAyudaFragmentoOpcionalPlugin:(Chequea si deseas incluir este fragmento)`;

    private readonly pluginUtils: PluginUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.pluginUtils = new PluginUtilsService();
    }

    public override execute(): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {

            const guid = this.pluginUtils.generarUUID();
            const id = `id-${guid}`;
            const name = id;
            const container = writer.createElement(OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.model, {
                 id,
                 name,
                 checked: false
            });

            const check = writer.createElement(OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.model, {
                id,
                checked: false
            });

            const description = writer.createElement(OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.model);

            const content = writer.createElement(OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.model);

            const descriptionDefaultText = writer.createText(`${this.helpTextOptionMessage}`);
            const descriptionDefaultTextElement = writer.createElement(GlobalConstant.MODEL_PARAGRAPH);

            const contentDefaultText = writer.createText(``);
            const contentDefaultTextElement = writer.createElement(GlobalConstant.MODEL_PARAGRAPH);

            writer.append(descriptionDefaultText, descriptionDefaultTextElement);
            writer.append(descriptionDefaultTextElement, description);

            writer.append(contentDefaultText, contentDefaultTextElement);
            writer.append(contentDefaultTextElement, content);

            writer.append(check, container);
            writer.append(description, container);
            writer.append(content, container);


            editor.model.insertObject(container, null, null, { setSelection: 'after' });
        });
    }
}
