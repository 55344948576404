import { Editor, Writer } from "ckeditor5";
import { InputModel } from "../../models/input/input-model";
import { InputDataViewUtilsService } from "../../utils/input/input-data-view-utils.service";
import { InputUtilsService } from "../../utils/input/input-utils.service";
import { InputBaseCommand } from "./input-base-command";

export default class AddInputCommand extends InputBaseCommand {

    private utilsService: InputUtilsService;
    private dataUtilsService: InputDataViewUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.utilsService = new InputUtilsService();
        this.dataUtilsService = new InputDataViewUtilsService();
    }

    public override execute(model: InputModel): void {
        const editor = this.editor;

        editor.model.change((writer: Writer)  => {
            const inputType = model.type;

            if (!this.utilsService.isTypeValid(inputType)) {
                return null;
            }

            const elementModel = this.dataUtilsService.createInputStructureModel(writer, inputType, model);

            editor.model.insertObject(elementModel, null, null, {setSelection: 'after'});
        } );
    }
}
